import useCalendarStore from "@/store/calendarStore";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import TableLoadingSpinner from "../ui/table/TableLoadingSpinner";
import DetailForm from "./DetailForm/DetailForm";
import { isMobile } from "react-device-detect";

export const Detail = () => {
  return (
    <DetailLayout>
      <DetailContent />
    </DetailLayout>
  );
};
export default Detail;

const DetailContent = () => {
  const { setCheckedAllTrue, isLoading, getEventById } = useCalendarStore();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const { event } = !isLoading && getEventById(id);

  useEffect(() => {
    setCheckedAllTrue();
  }, []);

  if (isLoading) return <TableLoadingSpinner width={"3rem"} height={"3rem"} />;

  if (!event) return <div>No Event Found</div>;

  return <DetailForm event={event} />;
};

const DetailLayout = ({ children }) => {
  return <Layout $ismobile={isMobile}>{children}</Layout>;
};

export const Layout = styled.main<{ $ismobile: boolean }>`
  position: relative;
  width: ${({ $ismobile }) => ($ismobile ? "90%" : "80%")};
  height: 100%;
  margin: 0 auto;
  /* padding-top: 2rem; */
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`;
