import { MdOutlineContentCopy } from "react-icons/md";
import styled from "styled-components";
import { changeDateForm } from "@/utils";
import {
  Flyer,
  FlyerSection,
  Section,
  Wrapper,
  InputSection
} from "../EventForm/EventForm";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { EVENT_CALENDAR_URL, DESC_KEYS } from "@/constants/calendar";
import { FaRegCalendarAlt, FaUser, FaFileAlt, FaHome } from "react-icons/fa";
import { FaLocationDot, FaRegImage } from "react-icons/fa6";
import { BiSolidMessageDetail } from "react-icons/bi";
import { IoPeopleSharp } from "react-icons/io5";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { isMobile } from "react-device-detect";
import { useDetailFiles } from "./DetailForm.hooks";
import {
  makeGoogleCalendarLink,
  makeiCalendarLink,
  makeOutLook365Link,
  makeOutLookLiveLink
} from "./DetailForm.utils";
import ColorBox from "@/components/ui/ColorBox";
import { useCopyToClipboard } from "usehooks-ts";
import * as _ from "lodash";
import { CalendarEvent } from "@/store/calendarStore";
import { useState } from "react";

const DETAIL_ITEMS = [
  { key: "location", label: "Location", component: FaLocationDot },
  {
    key: "description.organization",
    label: isMobile ? "Host" : DESC_KEYS.organization,
    component: FaHome
  },
  {
    key: "description.organizationContact",
    label: isMobile ? "Host Contact" : DESC_KEYS.organizationContact,
    component: MdEmail
  },
  {
    key: "description.link",
    label: DESC_KEYS.link,
    component: FaExternalLinkAlt
  },
  // { key: DESCRIPTION_FIELD[3], label: DESCRIPTION_FIELD[3], component: FaUser },
  // {
  //   key: DESCRIPTION_FIELD[4],
  //   label: DESCRIPTION_FIELD[4],
  //   component: VscBlank
  // },
  // {
  //   key: DESCRIPTION_FIELD[5],
  //   label: DESCRIPTION_FIELD[5],
  //   component: VscBlank
  // },
  {
    key: "description.speakers",
    label: DESC_KEYS.speakers,
    component: IoPeopleSharp
  },
  {
    key: "description.description",
    label: DESC_KEYS.description,
    component: BiSolidMessageDetail
  }
];

const SHARE_ITEMS = [
  { label: "Google Calendar", function: makeGoogleCalendarLink },
  { label: "iCalendar", function: makeiCalendarLink },
  { label: "Outlook Live", function: makeOutLookLiveLink },
  { label: "Outlook 365", function: makeOutLook365Link }
];

const DetailForm = ({ event }: { event: CalendarEvent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setHovered] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [copiedText, copy] = useCopyToClipboard();

  const { flyer, document } = useDetailFiles(id); // flyer, document url 가져오기

  const formattedDate = changeDateForm(event.start, event.end);
  const time = formattedDate.split("/");

  const CloseBtnClick = () => {
    if (location.state) navigate(-1);
    else navigate(EVENT_CALENDAR_URL);
  };

  const handleClickCopyLink = () => {
    // copy current url
    copy(window.location.href).then(() => {
      alert("url copied!");
    });
  };

  const handleGoogleAnalytics = label => {
    window.gtag("event", "spacecalendar_event_share", {
      channel: label,
      event_name: event.summary
    });
  };

  const handleiCalendar = label => {
    if (label === "iCalendar")
      alert("Open Downloaded File to Add to iCalendar");
  };

  return (
    <Section>
      <DetailWrapper $ismobile={isMobile}>
        <DetailInputSection>
          <HStack>
            <CloseBtn src="/svg/close-black.svg" onClick={CloseBtnClick} />
            <AddBtnSection
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              Add to my calendar
              {isHovered && (
                <AddBtnWrapper>
                  {SHARE_ITEMS.map((item, idx) => {
                    return (
                      <AddBtn
                        key={idx}
                        href={item.function(event)}
                        target="_blank"
                        rel="noreferrer"
                        download={`${event.summary}.ics`}
                        onClick={() => {
                          handleGoogleAnalytics(item.label);
                          handleiCalendar(item.label);
                        }}
                      >
                        {item.label}
                      </AddBtn>
                    );
                  })}
                </AddBtnWrapper>
              )}
            </AddBtnSection>
          </HStack>
          <Title onClick={handleClickCopyLink}>
            <ColorBox color={event.backgroundColor} />
            {event.summary}
            <MdOutlineContentCopy />
          </Title>
          <DetailItem>
            <Items>
              <FaRegCalendarAlt className="icon" />
              <Label $ismobile={isMobile}>Schedule</Label>
            </Items>
            <Value>{formattedDate}</Value>
          </DetailItem>
          {DETAIL_ITEMS.map((item, index) => {
            const IconComponent = item.component;
            const value = getNestedValue(event, item.key);
            const isSpeakers = item.key === "description.speakers";
            if (!isSpeakers)
              return (
                value && (
                  <DetailItem key={index}>
                    <Items>
                      <IconComponent className="icon" />
                      <Label $ismobile={isMobile}>{item.label}</Label>
                    </Items>
                    <Value>
                      {item.key === "description.link" ? (
                        <a href={value} target="_blank" rel="noreferrer">
                          {value}
                        </a>
                      ) : (
                        value
                      )}
                    </Value>
                  </DetailItem>
                )
              );

            return (
              value.length > 0 && (
                <DetailItem key={index}>
                  <Items>
                    <IconComponent className="icon" />
                    <Label $ismobile={isMobile}>{item.label}</Label>
                  </Items>
                  {
                    <Stack>
                      {value.map((speaker, index) => (
                        <Value>{speaker}</Value>
                      ))}
                    </Stack>
                  }
                </DetailItem>
              )
            );
          })}
          {document && (
            <DetailItem>
              <Items>
                <FaFileAlt className="icon" />
                <Label $ismobile={isMobile}>Document</Label>
              </Items>
              <a href={document} target="_blank" rel="noreferrer">
                <Value>download</Value>
              </a>
            </DetailItem>
          )}
        </DetailInputSection>
        {flyer && (
          <DetailFlyerSection>
            <Flyer src={flyer} alt="" />
          </DetailFlyerSection>
        )}
      </DetailWrapper>
    </Section>
  );
};

export default DetailForm;

const DetailWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DetailFlyerSection = styled(FlyerSection)`
  height: 100%;
`;

const DetailInputSection = styled(InputSection)`
  padding: 0;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  margin-bottom: 1.5rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  width: auto;
  word-wrap: break-word;

  cursor: pointer;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: start;
  /* gap: 1rem; */
  .icon {
    width: 1.3rem;
    height: 1.3rem;
  }
`;

const Value = styled.div`
  width: 100%;
  white-space: pre-wrap;
  /* background-color: lightgray; */
  a {
    display: inline-block;
    max-width: 100%;
    white-space: pre-wrap;
    word-break: break-word; /* 긴 단어를 줄 바꿈하도록 */
    overflow-wrap: break-word; /* 긴 단어를 줄 바꿈하도록 */
  }
`;

const Label = styled.span<{ $ismobile: boolean }>`
  width: ${({ $ismobile }) => ($ismobile ? "10rem" : "15rem")};
  margin-left: 0.5rem;
`;

const HStack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Items = styled.div`
  display: flex;
  align-items: center;
`;

const AddBtnSection = styled.a`
  position: relative;
  top: 0;
  right: 0;
  padding: 0.5rem 0.7rem;

  border-radius: 5px;

  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  background-color: #001326;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;

  /* &:hover {
    transform: scale(0.95);
  } */
`;

const AddBtnWrapper = styled.div`
  position: absolute;
  top: 100%;

  width: 100%;
  border-radius: 5px;

  box-shadow: 5px 5px 10px #000000a0;
  background-color: #ffffff;
`;

const AddBtn = styled.a`
  display: block;
  padding: 0.5rem 0.7rem;
  box-sizing: border-box;

  color: #001326;
  text-decoration: none;
`;

export const CloseBtn = styled.img`
  width: 2rem;
  height: 2rem;

  cursor: pointer;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 0.5rem;
`;

function getNestedValue(obj: any, key: string) {
  return key.split(".").reduce((acc, part) => acc && acc[part], obj);
}
