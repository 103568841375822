import styled from "styled-components";
import { PageLayout, Title } from "@/components/ui/PageTemplate";

export const Layout = styled(PageLayout)`
  width: 70rem;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
`;

export const Header = styled(Title)`
  @media screen and (max-width: 899px) {
    width: 90%;
  }
`;

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 899px) {
    width: 90%;
  }
`;

export const DescriptionWrapper = styled.section`
  width: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
`;

export const ResourceWrapper = styled.section`
  width: 35rem;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 0.5rem; */
`;

export const Resource = styled.article`
  width: 100%;
  height: 4rem;
  display: grid;
  grid-template-columns: [column1] 1fr [column2] 2rem [column3];
  grid-template-rows: [row1] 2rem [row2] 2rem [row3];
  column-gap: 1rem;
`;

export const ResourceTitle = styled.div`
  font-size: 1.3rem;
  font-weight: 700;
  color: #ffffff;
  white-space: pre;

  display: flex;
  align-items: end;
  justify-content: start;
`;

export const ResourceSubtitle = styled.div`
  grid-area: row2/column1/row3/column2;
  font-size: 1rem;
  font-weight: 300;
  font-size: 0.9rem;
  color: #ffffff;
  margin-left: 1rem;
  line-height: 1;
`;

export const ResourceDescription = styled.div`
  font-size: 0.8rem;
  color: #ffffff;
  font-weight: 300;
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
`;

export const SvgWrapper = styled.section`
  grid-area: row1/column2/row3/column3;
  width: 2rem;
  height: 2rem;
  background-color: #ffffff;

  place-self: center;

  > a {
    width: 100%;
    height: 100%;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
