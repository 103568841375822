import { useEffect } from "react";
import Home from "./Home";
import About from "./company/About";
import Contact from "./company/Contact";
import Mission from "./company/Mission";
import Team from "./company/Team";
import News from "./news/News";
import Astro1 from "./product/Astro1";
import AstroLibrary from "./product/AstroLibrary";
import AstroOrca from "./product/AstroOrca";
import Resources from "./technology/Resources";
import STAI from "./technology/STAI";
import Voronoi from "./technology/Voronoi";
import { LOCALSTORAGE_KEY } from "@/constants/calendar";
import SpaceTalk from "./product/SpaceTalk";
import Saudi from "./company/Saudi";

const NewBody = () => {
  useEffect(() => {
    //clicked Contact in SpaceCalendar
    if (localStorage.getItem(LOCALSTORAGE_KEY)) {
      const page = document.getElementById("Contact");
      page && page.scrollIntoView({ behavior: "smooth" });
      localStorage.removeItem(LOCALSTORAGE_KEY);
    }
  }, []);

  return (
    <>
      <Home />
      <Saudi />
      <SpaceTalk />
      <Astro1 />
      <AstroOrca />
      <AstroLibrary />
      <STAI />
      <Voronoi />
      <Resources />
      <News />
      <About />
      <Mission />
      <Team />
      <Contact />
    </>
  );
};

export default NewBody;
