import {
  ACCESS_TOKEN,
  EVENT_CALENDAR_URL,
  DESCRIPTION_FIELD,
  DESC_KEYS
} from "@/constants/calendar";
import { CalendarTime } from "@/store/calendarStore";
import { streamToJson } from "@/utils/common";
import axios from "axios";
import dayjs from "dayjs";

export const fetchCalendarAccessToken = async () => {
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDAR_TOKEN_ENDPOINT,
    {
      redirect: "follow",
      method: "GET",
      headers: {
        "Content-Type": "text/plain"
      }
    }
  );

  const data = await streamToJson(response);
  const { token } = data.data;

  return token;
};

export const getPresignedUrl = async (filePath: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_CALENDAR_API_URL}/presigned-urls`,
    {
      key: filePath
    },
    {
      withCredentials: true
    }
  );

  return response.data.preSignedUrl;
};

export const uploadFileToS3 = async (file: File, url: string) => {
  const response = await axios.put(url, file, {
    headers: {
      "Content-Type": file.type
    }
  });

  return response;
};

const makeDescriptionJSon = (data, speakers) => {
  const { description } = data;
  const descriptionString = DESCRIPTION_FIELD.map(field => {
    if (field === DESCRIPTION_FIELD[6]) {
      return `${field}: ${speakers.map(speaker => `${speaker}`).join(", ")}`;
    }

    const descKey = Object.entries(DESC_KEYS).find(
      ([key, value]) => value === field
    );
    if (!descKey) return `${field}: ${description[field]}`;

    const key = descKey[0];
    const value = description[key] || "";

    return `${field}: ${value}`;
  }).join("\n");

  const jsonString = `${descriptionString}`;
  return jsonString;
};

export const makeEventForm = (data, speakers) => {
  const jsonString = makeDescriptionJSon(data, speakers);
  const endDate = new Date(data.end);
  endDate.setDate(endDate.getDate() + 1);

  const event = {
    summary: data.summary,
    location: data.location,
    description: jsonString,
    start: data.isAllday
      ? {
          date: data.start
        }
      : {
          dateTime: `${data.start}:00`,
          timeZone: data.timeZone
        },
    end: data.isAllday
      ? {
          date: endDate.toISOString().split("T")[0]
        }
      : {
          dateTime: `${data.end}:00`,
          timeZone: data.timeZone
        }
  };
  return event;
};

export const makeFileName = (key: string, origin: string) => {
  const extension = origin.split(".").pop();

  return `${key}.${extension}`;
};

export const uploadFile = async (key: string, file: File) => {
  const uploadUrl = await getPresignedUrl(key);
  const response = await uploadFileToS3(file, uploadUrl);
  return response;
};

export const deleteFile = async (key: string) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_CALENDAR_API_URL}/files/${key}`
  );

  return response;
};

export const getFormattedDate = (date: CalendarTime, type: string) => {
  const isAllday = !!date.date;
  let formattedDate: any = isAllday ? dayjs(date.date) : dayjs(date.dateTime);

  if (isAllday) {
    if (type === "end") {
      formattedDate = formattedDate.subtract(1, "day");
    }
    formattedDate = formattedDate.format("YYYY-MM-DD");
  } else {
    formattedDate = formattedDate.format("YYYY-MM-DDTHH:mm");
  }

  return formattedDate;
};
