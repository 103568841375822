import styled from "styled-components";
import {
  PageLayout,
  TitleWrapper,
  Description
} from "@/components/ui/PageTemplate";

export const Layout = styled(PageLayout)`
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
`;

export const Container = styled.main`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VoronoiTitle = styled(TitleWrapper)`
  width: 37rem;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const DescriptionWrapper = styled.section`
  width: 100%;
  /* height: 18rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
`;

export const VoronoiDescription = styled(Description)`
  font-size: 1.4rem;
`;

export const VideoWrapper = styled.section`
  width: 27rem;
  box-sizing: border-box;
  padding-top: 0.8rem;

  display: flex;
  /* justify-content: center; */
  align-self: center;
`;

export const Video = styled.video`
  width: 27rem;
  pointer-events: none;
`;
