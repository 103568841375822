import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Logo = () => {
  const navigate = useNavigate();
  const onClick = () => {
    // const onSubMenuClick = new CustomEvent("onSubMenuClick", {
    //   bubbles: false,
    //   cancelable: false,
    //   detail: {
    //     index: 0
    //   }
    // });
    // navigate("/");
    // document.dispatchEvent(onSubMenuClick);
    const page = document.getElementById("Home");

    if (page) {
      page.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <LogoImg alt="spacemap" src="/logo/maintype_3.png" onClick={onClick} />
  );
};

export default Logo;

const LogoImg = styled.img`
  width: 13rem;
  cursor: pointer;
`;
