import styled from "styled-components";
import { PageLayout, Title, Content } from "@/components/ui/PageTemplate";

export const Layout = styled(PageLayout)`
  width: 67rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const AstroTitle = styled(Title)`
  width: 60.5rem;
  line-height: 2rem;
`;

export const DescriptionWrapper = styled.section`
  width: 40rem;
  height: 20rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

export const AstroLibraryContent = styled(Content)`
  justify-content: center;
  gap: 2.5rem;
`;

export const ImgWrapper = styled.section`
  width: 18rem;
  height: 18rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  padding-top: 2rem;
  width: 100%;
  height: auto;
`;
