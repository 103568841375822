import React, { useEffect, useRef } from "react";
import PageTemplate from "@/components/ui/PageTemplate";
import Googlemap from "@/components/company/Googlemap";
import SNS from "@/components/company/SNS";
import ContactForm from "@/components/company/ContactForm";
import { Layout, Content, Title, GoogleMapWrapper } from "./ContactStyle";
import { LOCALSTORAGE_KEY } from "@/constants/calendar";
import { isMobile } from "react-device-detect";
import useObserver from "@/hooks/page/useObserver";

const Contact = () => {
  const contactRef = useRef(null);
  useObserver("content11");

  useEffect(() => {
    if (isMobile && localStorage.getItem(LOCALSTORAGE_KEY)) {
      contactRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
      localStorage.removeItem(LOCALSTORAGE_KEY);
    }
  }, []);

  return (
    <PageTemplate id="Contact" backgroundColor={"#ffffff"}>
      <Layout id="content11" ref={contactRef}>
        <Content>
          <Title>Contact us</Title>
          <ContactForm />
        </Content>

        <Content>
          <Title>Follow us</Title>
          <SNS />
        </Content>

        <GoogleMapWrapper
          onWheel={e => e.stopPropagation()}
          onTouchEnd={e => e.stopPropagation()}
        >
          <Googlemap />
        </GoogleMapWrapper>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Contact);
