import styled from "styled-components";
import { PageLayout, Title } from "@/components/ui/PageTemplate";

export const Layout = styled(PageLayout)`
  justify-content: center;
  align-items: center;
  gap: 4rem;
`;

export const AstroTitle = styled(Title)`
  line-height: 2rem;
`;

export const ContentWrapper = styled.section`
  width: 30rem;
  height: 31rem;
  /* margin-top: 1.3rem; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ConjunctionWrapper = styled.section`
  width: 30rem;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  transform: translateY(${props => `${props.$offset}`});
  transition: transform ease 0.5s;
`;

export const ConjunctionViewer = styled.section`
  position: relative;
  width: 30rem;
  height: 38rem;

  box-sizing: border-box;
  margin: 2rem 0;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    height: 40rem;
  }
`;

export const Video = styled.video`
  width: 31rem;
  aspect-ratio: 1/1;
  pointer-events: none;
`;

export const DescriptionWrapper = styled.section`
  width: 100%;
  height: 37.5rem;
  box-sizing: border-box;
  padding-top: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ChevronUpWrapper = styled.section`
  position: absolute;
  top: -2rem;
  left: 20rem;
  transform: translateX(-50%);
  height: 2rem;
  margin: 1rem auto;

  color: #ffffff;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  cursor: pointer;
  z-index: 10;
  @media screen and (max-width: 700px) {
    top: 0rem;
    left: 22rem;
  }
`;

export const ChevronDownWrapper = styled(ChevronUpWrapper)`
  top: calc(100% - 2rem);
  @media screen and (max-width: 700px) {
    top: 100%;
  }
`;
