import { CALENDAR_TYPE } from "@/constants/calendar";
import useCalendarStore from "@/store/calendarStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const CalendarTab = () => {
  // const { calendarType, setCalendarType } = useCalendarStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarType = searchParams.get("view") || "calendar";
  const navigate = useNavigate();

  const onTabClick = type => {
    // setCalendarType(type);
    // navigate(`/spacecalendar?view=${type}`);
    searchParams.set("view", type);
    setSearchParams(searchParams);
  };

  return (
    <Section>
      {CALENDAR_TYPE.map((type, index) => {
        return (
          <Tab
            key={index}
            $active={calendarType === type.value}
            onClick={() => onTabClick(type.value)}
          >
            {type.label}
          </Tab>
        );
      })}
    </Section>
  );
};

export default CalendarTab;

const Section = styled.nav`
  width: 15rem;
  height: 2.5rem;

  box-shadow: 0px 1px 5px 0 rgb(0, 0, 0, 0.6);
  border-radius: 2rem;

  display: flex;

  @media screen and (max-width: 700px) {
    width: 20rem;
    height: 3rem;
  }
`;

const Tab = styled.ul<{ $active }>`
  width: 50%;
  height: 100%;
  padding: 0;
  margin: 0;

  border-radius: 2rem;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $active }) => $active && "#ffffff"};
  background-color: ${({ $active }) => $active && "#001326"};

  cursor: pointer;
`;
