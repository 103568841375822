import { CalendarEvent, CalendarTime } from "@/store/calendarStore";
import { padNumber } from "@/utils";
import axios from "axios";
import { format } from "prettier";

export const getFilePathById = async (id: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CALENDAR_API_URL}/files/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// example
// https://www.google.com/calendar/event?action=TEMPLATE&dates=20240627T140000/20240627T160000&text=Masterclass%3A%20Raising%20Money%20as%20a%20Hot%20Tech%20Startup&details=%3Cp%3EJoin+for+insight+of+real-world+scenarios+from+entrepreneur%2C+founder+and+investor+Mathieu+Goudot.+This+masterclass+is+not+intended+to+tell+you+what+to+do%2C+but+to+share+real+anecdotes+and+examples+from+real+situations+encountered+by+Mathieu+across+his+10+years+as+a+founder+that+raised+money+successfully+%28and+unsuccessfully%29+and+1+year+as+a+member+of+the+board+of+investors+of+a+European+space+startup+accelerator+Applied+examples+will+be+provided%2C+with+personal+feedback+on+what+went+right+and+wrong.+It+will+be+guerrilla-style+advice%2C+focused+on+maximising+the+impact+for+you+as+a+founder.%3C%2Fp%3E+%3Cp%3E%3Cem%3EMathieu+Goudot+is+a+master+and+fractional+leader+at+Masters+of+Scale%2C+a+tech+startup+exec+and+a+mentor+to+several+aerospace+startups.%3C%2Fem%3E%3C%2Fp%3E+%3Cp%3E%3Cem%3EOpen+to+anyone+with+an+interest+and+free+for+community+members+%26amp%3B+attendees+can+join+the+free+networking+%26amp%3B+drinks+after+the+workshop.%3C%2Fem%3E%3C%2Fp%3E+&location=Space%20Business%20Innovation%20Centre,%20Kapteynstraat,%20Noordwijk,%20Noord%20Holland,%202201%20CZ,%20Netherlands&trp=false&ctz=Europe/Amsterdam&sprop=website:https://spacenews.com

export const makeGoogleCalendarLink = (event: CalendarEvent) => {
  const { summary, location, description, start, end } = event;

  let dates = "";
  if (start) {
    dates = `${makeDateFormat(start)}`;
  }

  if (end) {
    dates += `/${makeDateFormat(end)}`;
  }

  if (dates.length > 0) {
    dates = "&dates=" + dates;
  }

  const descriptionString = getFormatDescription(description);
  const details = description.description
    ? `&details=${encodeURIComponent(descriptionString)}`
    : "";

  const locationStr = location ? `&location=${location}` : "";

  const timeZone = start.timeZone ? `&ctz=${start.timeZone}` : "";

  const sprops = `&sprop=website:https://www.spacemap42.com`;
  const queryString = `action=TEMPLATE&text=${summary}${dates}${details}${locationStr}${timeZone}${sprops}`;

  return `https://www.google.com/calendar/render?${queryString}`;
};

export const makeOutLook365Link = (event: CalendarEvent) => {
  const { summary, location, start, end, description } = event;

  const locationStr = location ? location : "";

  const startDate = makeOutLookDateFormat(makeDateFormat(start));
  const endDate = makeOutLookDateFormat(makeDateFormat(end));

  const descriptionString = getFormatDescription(description);
  const details = description.description
    ? encodeURIComponent(descriptionString)
    : "";

  const queryString = new URLSearchParams({
    path: "/calendar/action/compose",
    rrv: "addevent",
    subject: summary,
    startdt: startDate,
    enddt: endDate,
    location: locationStr,
    body: details
  }).toString();

  return `https://outlook.office.com/owa/?${queryString}`;
};

export const makeOutLookLiveLink = (event: CalendarEvent) => {
  const { summary, location, start, end, description } = event;

  const locationStr = location ? location : "";

  const startDate = makeOutLookDateFormat(makeDateFormat(start));
  const endDate = makeOutLookDateFormat(makeDateFormat(end));

  const descriptionString = getFormatDescription(description);

  const queryString = new URLSearchParams({
    path: "/calendar/action/compose",
    rrv: "addevent",
    subject: summary,
    startdt: startDate,
    enddt: endDate,
    location: locationStr,
    body: descriptionString
  });

  return `https://outlook.live.com/owa/?${queryString}`;
};

export const makeiCalendarLink = (event: CalendarEvent) => {
  const { summary, location, start, end, description } = event;

  const locationStr = location ? location : "";

  const startDate = makeDateFormat(start);
  const endDate = makeDateFormat(end);

  const descriptionString = getiCalFormatDescription(description);

  const generateICSFile = () => {
    const icsContent =
      "BEGIN:VCALENDAR\r\n" +
      "VERSION:2.0\r\n" +
      "PRODID:-//Your Organization//NONSGML v1.0//EN\r\n" +
      "BEGIN:VTIMEZONE\r\n" +
      "TZID:Asia/Seoul\r\n" +
      "BEGIN:STANDARD\r\n" +
      "TZOFFSETFROM:+0900\r\n" +
      "TZOFFSETTO:+0900\r\n" +
      "TZNAME:KST\r\n" +
      "DTSTART:19700101T000000\r\n" +
      "END:STANDARD\r\n" +
      "END:VTIMEZONE\r\n" +
      "BEGIN:VEVENT\r\n" +
      `UID:${new Date().getTime()}@example.com\r\n` +
      `DTSTAMP:${new Date().toISOString().replace(/[-:]/g, "").split(".")[0]}Z\r\n` +
      `DTSTART;TZID=Asia/Seoul:${startDate}\r\n` +
      `DTEND;TZID=Asia/Seoul:${endDate}\r\n` +
      `SUMMARY:${summary}\r\n` +
      `LOCATION:${locationStr}\r\n` +
      `DESCRIPTION:${descriptionString.replace(/\n/g, "\\n")}\r\n` +
      "END:VEVENT\r\n" +
      "END:VCALENDAR\r\n";

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8"
    });
    const url = URL.createObjectURL(blob);

    return `${url}`;
  };

  return generateICSFile();
};

const makeOutLookDateFormat = time => {
  const year = time.substring(0, 4);
  const month = time.substring(4, 6);
  const day = time.substring(6, 8);
  const hour = time.substring(9, 11);
  const minute = time.substring(11, 13);
  const second = time.substring(13, 15);

  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

const makeDateFormat = (date: CalendarTime) => {
  // example: 2024-06-27 -> 20240627T140000
  const isAllDay = !!date.date;
  if (isAllDay) {
    return makeAllDayDateFormat(date.date);
  }

  return makeSpecificTimeDateFormat(date.dateTime);
};

const makeAllDayDateFormat = (date: string) => {
  let dateString = date.split("-").join("");
  dateString += "T000000";

  return encodeURIComponent(dateString);
};

const makeSpecificTimeDateFormat = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = padNumber(dateObj.getMonth() + 1);
  const day = padNumber(dateObj.getDate());
  const hour = padNumber(dateObj.getHours());
  const minute = padNumber(dateObj.getMinutes());
  const second = padNumber(dateObj.getSeconds());

  let dateString = `${year}${month}${day}T${hour}${minute}${second}`;

  return encodeURIComponent(dateString);
};

const getFormatDescription = (description: any) => {
  const fields = [
    { key: "link", label: "Link" },
    { key: "organization", label: "Host/Organization" },
    { key: "description", label: "Description" }
  ];
  const descriptionString = fields
    .map(field => {
      let value = description[field.key] || "";
      if (field.key === "link") {
        value = `<a href="${value}" target="_blank" rel="noreferrer">${value}</a>`;
      }
      return `${field.label}: ${value}`;
    })
    .join("<br><br>");

  return descriptionString;
};

const getiCalFormatDescription = (description: any) => {
  const fields = [
    { key: "link", label: "Link" },
    { key: "organization", label: "Host/Organization" },
    { key: "description", label: "Description" }
  ];
  const descriptionString = fields
    .map(field => {
      let value = description[field.key] || "";
      if (field.key === "link") {
        value = `${value}`;
      }
      return `${field.label}: ${value}`;
    })
    .join("\n\n");

  return descriptionString;
};
