import { SELECT_TYPE, DESC_KEYS, CALENDAR_ITEM_COLOR } from "@/constants/calendar";
import { useQueryCalendar } from "@/query/calendar/use-query-calendar";
import useCalendarStore from "@/store/calendarStore";
import { useEffect } from "react";

const useInitCalendarData = () => {
  const { data: globalEvents, isLoading: globalLoading } = useQueryCalendar(
    SELECT_TYPE[0]
  );
  const { data: koreaEvents, isLoading: koreaLoading } = useQueryCalendar(
    SELECT_TYPE[1]
  );
  const { events, checkedEvent, setEvents, setIsLoading } = useCalendarStore();

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (globalLoading || koreaLoading) {
      setIsLoading(true);

      return;
    }

    if (globalEvents && globalEvents.length > 0 && checkedEvent[SELECT_TYPE[0]]) { 
      initEvents(globalEvents, "GLOBAL");
    }

    if (koreaEvents && koreaEvents.length > 0 && checkedEvent[SELECT_TYPE[1]]) {
      initEvents(koreaEvents, "KOREA");
    }

    setIsLoading(false);


    // console.log("eventArray", eventArray);
    // eventArray.sort((a, b) => {
    //   const dateA = new Date(a.start);
    //   const dateB = new Date(b.start);
    //   return dateA - dateB;
    // });

    // setEvents(eventArray);
  }, [globalEvents, koreaEvents, checkedEvent, globalLoading, koreaLoading]);

  const initEvents = (events: any[], type: string) => {
    const parsedEvents = events.map(event => parseEvents(event));
    // console.log('parsedEvents', parsedEvents)
    const color = CALENDAR_ITEM_COLOR[type];
    const eventsWithColor = parsedEvents.map(event => ({ ...event, backgroundColor: color, borderColor: color }));
    setEvents(eventsWithColor, type);
  }


};

export default useInitCalendarData;



export const filterEvents = (events, setEvents, filter) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  if (events.length === 0) {
    setEvents([]);
    return;
  }

  const filtered = events.filter(event => {
    const eventEndDate = new Date(event.end);

    if (!filter || filter === "Upcoming") {
      return eventEndDate > now;
    } else if (filter === "Month") {
      const isEventInMonth =
        eventEndDate.getFullYear() === currentYear &&
        eventEndDate.getMonth() === currentMonth;
      const isAllDayAndEndFirstDay =
        event.allDay && eventEndDate.getDate() === 1;

      return isEventInMonth && !isAllDayAndEndFirstDay;
    } else if (filter === "Year") {
      return eventEndDate.getFullYear() === currentYear;
    }

    return false;
  });

  setEvents(filtered);
};

const parseEvents = event => {
  event = Object.entries(event).reduce((acc, [key, value]) => {
    return { ...acc, [key]: !!value ? value : "" };
  }, {});


  let parsedDescription;
  if (event.description) {
    parsedDescription = parseDescription(event.description);
  }


  return {
    ...event,
    description: {...parsedDescription},
  };
}



function convertBrToNewlineAndStripHtmlTags(str) {
  // <br> 태그를 줄바꿈 문자로 변경
  let result = str.replace(/<br\s*\/?>/gi, "\n");

  // 모든 HTML 태그를 제거
  result = result.replace(/<\/?[^>]+(>|$)/g, "");

  return result;
}

const parseDescription = description => {
  const formattedDescription = convertBrToNewlineAndStripHtmlTags(description);
  const lines: string[] = formattedDescription.split("\n");


  // find the first line that contains "Description"
  const descIndex = lines.findIndex(line => line.indexOf("Description") !== -1);

  if (descIndex === -1) {
    return {};
  }
  const informations = lines.slice(0, descIndex);
  const formattedInformations = informations.map(info => {
    const colonIndex = info.indexOf(":");
    if (colonIndex !== -1) {

      let key = info.slice(0, colonIndex).trim();
      let value = info.slice(colonIndex + 1).trim();

      if (key === "Speakers") {
        value = customSplit(value);
      } else if (value.length === 1) {
        value = value[0];
      }

      return { key, value };
    }
  });

  // desc first line과 desc secondline을 합쳐서 description으로 만들어줌

  const descFirstLine = lines[descIndex].split("Description:")[1];
  const descSecondLine = lines.slice(descIndex + 1).join("\n");

  const desc = (descFirstLine + "\n" + descSecondLine).trim();

  const resultList = [
    ...formattedInformations,
    { key: "Description", value: desc }
  ];

  return resultList.reduce((acc, curr) => {
    const descKey = Object.entries(DESC_KEYS).filter(([key, value]) => value === curr.key);
    if (descKey.length === 0) {
      return acc;
    }

    const key = descKey[0][0];

    acc[key] = curr.value;

    return acc;
  }, {});
};




function customSplit(str) {
  const result = [];
  let current = '';
  let inQuotes = false;
  let inParentheses = 0;
  
  // 문자열의 앞뒤 대괄호와 작은 따옴표를 제거
  const trimmedStr = str.replace(/\[|\]/g, '');
  
  for (let i = 0; i < trimmedStr.length; i++) {
    const char = trimmedStr[i];
    
    if (char === "'") {
      inQuotes = !inQuotes;
      current += char;
    } else if (char === '(') {
      inParentheses++;
      current += char;
    } else if (char === ')') {
      inParentheses--;
      current += char;
    } else if (char === ',' && !inQuotes && inParentheses === 0) {
      result.push(current.trim());
      current = '';
    } else {
      current += char;
    }
  }
  
  if (current) {
    result.push(current.trim());
  }
  
  return result.map(item => item.replace(/^'|'$/g, '').trim());
}
