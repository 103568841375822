import { api } from "@/modules/api";
import { AdvancedSearchParamsType, PPDBResponseDataType } from "@/types";
import { objectToURL } from "@/utils";
import { useQuery } from "react-query";

export const requestApiGetConjunctions = async (
  query: AdvancedSearchParamsType
) => {
  try {
    const response = await api.GET<null, PPDBResponseDataType>(
      process.env.REACT_APP_CDM_API_URL + "/cdm/recent" + objectToURL(query)
    );
    return {
      conjunctions: response.data.conjunctions,
      totalCount: response.data.totalCount,
      currentCount: response.data.currentCount
    };
  } catch (err) {
    const emptyResult = {
      result: []
    };
    throw err.response
      ? { message: err.response.data.message, data: emptyResult }
      : { message: new Error("Wrong Input Parameter."), data: emptyResult };
  }
};

export const useQueryGetConjunctions = (query, queryKey) => {
  const apiMethod = requestApiGetConjunctions;
  return useQuery([queryKey, query], () => apiMethod(query), {
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });
};
