import { calendarIdMap } from "@/constants/calendar";
import { useQuery } from "react-query";

export const requestCalendar = async (type, query) => {
  const calendarId = calendarIdMap[type];
  const apiKey = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;
  const queryString = query ? `&q=${query}` : "";
  const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}${queryString}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    return data?.items;
  } catch (err) {
    console.error(err);
  }
};

export const useQueryCalendar = (type, query = "") => {
  const apiMethod = requestCalendar;

  return useQuery(["calendar", type, query], () => apiMethod(type, query), {
    staleTime: 1000 * 60 * 60 * 1
  });
};
