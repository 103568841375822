import React, { useEffect, useState } from "react";

import Table from "@/components/ui/table/Table";
import { TableQueryKeyType } from "../header/Search";
import styled from "styled-components";
import { useQueryGetConjunctions } from "@/query/conjunctions/use-query-cdm";
import { OPTION_LIST } from "@/constants/context";
import ConjunctionSelect from "@/components/core/select/ConjunctionSelect";
import { useConjunctionTable } from "./ConjunctionTable-hooks";

type ConjunctionTableProps = {
  queryKey: TableQueryKeyType;
  limit: number;
  totalPage: number;
  title: string;
  description: string;
};

export const headers = [
  "",
  "Primary",
  "Secondary",
  "TCA(UTC)",
  "DCA(km)",
  "PoC"
];
export const columns = ["pName", "sName", "tcaTime", "dca", "poc"];

export const ConjunctionTable = ({
  queryKey,
  limit,
  totalPage,
  title,
  description
}: ConjunctionTableProps) => {
  // const { searchType, inputValue, advancedInputValue } = queryKey;
  const {
    selected,
    setSelected,
    page,
    setPage,
    isLoading,
    isFetching,
    tableData
  } = useConjunctionTable({ limit, queryKey });
  const SELECT_TYPE = "HOME";

  return (
    <Container>
      <Header>
        <Title>
          {title}
          <Description>{description}</Description>
        </Title>
        <ConjunctionSelect
          selected={selected}
          setSelected={setSelected}
          type={SELECT_TYPE}
        />
      </Header>
      <Table
        headers={headers}
        columns={columns}
        data={tableData}
        page={page}
        setPage={setPage}
        isLoading={isLoading || isFetching}
        totalPages={totalPage}
        limit={limit}
      />
    </Container>
  );
};

export default React.memo(ConjunctionTable);

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  gap: 8px;
`;

const Header = styled.header`
  padding: 0 0 0 8px;
  margin: 0;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
`;

const Description = styled.div`
  font-size: 0.9rem;
  font-weight: 300;
  margin: 0;
  align-self: center;
`;

const Select = styled.select`
  width: 6.5rem;
  height: 2rem;

  border: 1px solid #fccb16;
  border-radius: 5px;
  background-color: transparent;

  color: #fccb16;
  font-weight: bold;
  text-align: center;

  font-size: 1rem;

  option {
    color: #000000;
    border-radius: 0;
  }
`;
