import { useEffect, useState } from "react";
import styled from "styled-components";
import { MENU, SUBMENU } from "@/constants/nav_bar";

const PageNavBar = () => {
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [curMenu, setCurMenu] = useState(null);
  const [curSubMenu, setCurSubMenu] = useState(null);

  const SetMenu = event => {
    setCurMenu(event.detail.menu);
    setCurSubMenu(event.detail.subMenu);
  };

  useEffect(() => {
    document.addEventListener("onChangeMenuColor", SetMenu);

    return () => {
      document.removeEventListener("onChangeMenuColor", SetMenu);
    };
  }, []);

  // const getPageIndex = (clickedMenu, clickedSubMenu) => {
  //   let index = 0;
  //   for (const key of MENU) {
  //     for (const subMenu of SUBMENU[key]) {
  //       if (subMenu === clickedSubMenu) break;
  //       else index++;
  //     }
  //     if (clickedMenu === key) break;
  //   }
  //   return index + 1;
  // };

  // const movePage = index => {
  //   const onSubMenuClick = new CustomEvent("onSubMenuClick", {
  //     bubbles: false,
  //     cancelable: false,
  //     detail: {
  //       index: index
  //     }
  //   });
  //   document.dispatchEvent(onSubMenuClick);
  // };

  // const onSubMenuClick = (e, clickedMenu, clickedSubMenu) => {
  //   e.stopPropagation();
  //   const index = getPageIndex(clickedMenu, clickedSubMenu);
  //   movePage(index);
  // };

  // const onMenuClick = clickedMenu => {
  //   let index;
  //   switch (clickedMenu) {
  //     case MENU[0]:
  //       index = 1;
  //       break;
  //     case MENU[1]:
  //       index = 4;
  //       break;
  //     case MENU[2]:
  //       index = 7;
  //       break;
  //     case MENU[3]:
  //       index = 8;
  //       break;
  //     // case MENU[4]:
  //     //   index = 12;
  //     //   break;
  //   }
  //   movePage(index);
  // };

  const handleNavClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const page = document.getElementById(id);

    if (page) {
      page.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <PageNav>
      <ul>
        {MENU.map((menu, index) => {
          return (
            <NavItems
              key={`${menu}${index}`}
              href={`#${SUBMENU[menu][0]}`}
              onClick={e => handleNavClick(e, SUBMENU[menu][0])}
              // onClick={() => onMenuClick(menu)}
              onMouseEnter={() => setHoveredMenu(menu)}
              onMouseLeave={() => setHoveredMenu(null)}
              $hoveredmenu={hoveredMenu}
              $element={menu}
              $curmenu={curMenu}
            >
              {menu}
              {hoveredMenu === menu && (
                <SubMenu>
                  {SUBMENU[menu].map((subMenu, idx) => {
                    return (
                      <SubMenuItems
                        key={`${subMenu}${idx}`}
                        href={`#${subMenu}`}
                        onClick={e => handleNavClick(e, subMenu)}
                        // onClick={e => onSubMenuClick(e, menu, subMenu)}
                        $element={subMenu}
                        $cursubmenu={curSubMenu}
                      >
                        {subMenu}
                      </SubMenuItems>
                    );
                  })}
                </SubMenu>
              )}
            </NavItems>
          );
        })}
      </ul>
    </PageNav>
  );
};

export default PageNavBar;

const PageNav = styled.nav`
  width: 40rem;
  margin-left: 1.5rem;
  > ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
  }
`;

const NavItems = styled.h1`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 1.3rem;
  font-weight: 700;

  color: ${props =>
    props.$hoveredmenu === props.$element || props.$curmenu === props.$element
      ? "#fccb15"
      : "#ffffff"};
  box-sizing: border-box;
  cursor: pointer;

  text-decoration: none;
`;

const SubMenu = styled.section`
  position: absolute;
  top: 100%;
  left: -0.5rem;
  width: 10rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  background-color: #000000;
  border-radius: 10px;
  z-index: 10;
`;

const SubMenuItems = styled.a`
  color: ${props =>
    props.$cursubmenu === props.$element ? "#fccb15" : "#ffffff"};
  font-size: 1rem;
  font-weight: 500;
  list-style: none;
  cursor: pointer;
  &:hover {
    color: #fccb15;
  }
  text-decoration: none;
`;
