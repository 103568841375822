import moment from "moment";
import { useState } from "react";
import { usePredictionWindow } from "./use-prediction-window";

export type AdvancedSearchStateType = {
  primary: string;
  secondary: string;
  tcaStart: string;
  tcaEnd: string;
  dcaLower: number;
  dcaUpper: number;
};

const advancedSearchInitialState: AdvancedSearchStateType = {
  primary: "",
  secondary: "",
  tcaStart: moment().toISOString(),
  tcaEnd: moment().add(24, "hours").toISOString(),
  dcaLower: 0,
  dcaUpper: 10
};

export const ADVANCED_SEARCH_INPUT_LABEL = {
  primary: "Primary",
  secondary: "Secondary",
  tcaStart: "TCA (From)",
  tcaEnd: "TCA (To)",
  dca: "DCA (km)"
};

export const useAdvancedInput = () => {
  const [input, setInput] = useState<AdvancedSearchStateType>(
    advancedSearchInitialState
  );

  const { predictionWindowString, minTCA, maxTCA } = usePredictionWindow();

  const handleChangeAdvancedInputValue = (
    key: keyof AdvancedSearchStateType,
    value: string | number
  ) => {
    setInput(prev => ({ ...prev, [key]: value }));
  };

  return {
    input,
    handleChangeAdvancedInputValue,
    minTCA,
    maxTCA,
    predictionWindowString
  };
};
