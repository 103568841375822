import useWindowSize from "@/hooks/page/useWindowSize";
import styled from "styled-components";

const PageTemplate = ({ id, backgroundColor, children }) => {
  const { width, height } = useWindowSize();

  return (
    <PageContainer
      $width={width}
      $height={height}
      $backgroundcolor={backgroundColor}
      id={id}
    >
      {children}
    </PageContainer>
  );
};

export default PageTemplate;

export const PageContainer = styled.section`
  width: ${props => `${props.$width}px`};
  min-width: 700px;
  height: ${props => `${props.$height}px`};
  box-sizing: border-box;
  padding-top: 5rem;
  background-color: ${props =>
    props.$backgroundcolor ? props.$backgroundcolor : "#000000"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 700px) {
    height: 500px;
    padding-top: 0;
  }
`;

export const PageLayout = styled.section`
  position: relative;
  width: 73.125rem;
  min-width: 700px;
  height: 43.75rem;
  box-sizing: border-box;
  padding: 0 3rem;
  margin: 0 auto;
  gap: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 700px) {
    aspect-ratio: 11/7;
    box-sizing: border-box;
    padding: 2rem;
    margin: 0;
  }
`;

export const TitleWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Title = styled.h1`
  width: 100%;
  margin: 0;
  font-size: 2.2rem;
  font-weight: 300;
  color: #ffffff;
  text-align: justify;
`;

export const SubTitle = styled.div`
  display: inline;
  font-size: 1.7rem;
`;

export const Emphasize = styled.span`
  color: #fccb16;
  display: inline;
`;

export const Content = styled.main`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Description = styled.article`
  color: white;
  text-align: justify;
  font-size: 1.3rem;
  font-weight: 300;
`;
