import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useDateParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const year = searchParams.get("year")
    ? Number(searchParams.get("year"))
    : new Date().getFullYear();
  const month = searchParams.get("month")
    ? Number(searchParams.get("month"))
    : new Date().getMonth() + 1;

  const initialDate = new Date(year, month - 1, 1);

  useEffect(() => {
    const year = searchParams.get("year");
    const month = searchParams.get("month");
    if (year && month) {
      return;
    }

    const currentYear = new Date().getFullYear().toString();
    const currentMonth = (new Date().getMonth() + 1).toString();
    searchParams.set("year", currentYear);
    searchParams.set("month", currentMonth);
    setSearchParams(searchParams);
  }, []);

  const handleDateChange = info => {
    const newDate = info.view.currentStart;
    const newYear = newDate.getFullYear();
    const newMonth = newDate.getMonth() + 1; // month is 0-based
    searchParams.set("year", newYear);
    searchParams.set("month", newMonth);
    setSearchParams(searchParams);
  };
  return {
    initialDate,
    handleDateChange
  };
};
