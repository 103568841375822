type ConjunctionTableDataType = {
  pId: number;
  pName: string;
  sId: number;
  sName: string;
  tcaTime: string;
  dca: number;
  poc: number;
};

export const convertTableData = (data: {
  conjunctions: any;
  totalCount: number;
  currentCount: number;
}): ConjunctionTableDataType[] => {
  return data.conjunctions.map(
    ({
      PRIMARY_OBJECT_NAME,
      PRIMARY_OBJECT_DESIGNATOR,
      SECONDARY_OBJECT_NAME,
      SECONDARY_OBJECT_DESIGNATOR,
      TCA,
      MISS_DISTANCE,
      MESSAGE_ID,
      COLLISION_PROBABILITY
    }) => ({
      CDM_ID: MESSAGE_ID,
      pName: PRIMARY_OBJECT_NAME,
      pId: PRIMARY_OBJECT_DESIGNATOR,
      sName: SECONDARY_OBJECT_NAME,
      sId: SECONDARY_OBJECT_DESIGNATOR,
      tcaTime: TCA.split("T").join("\n"),
      dca: Math.round((Number(MISS_DISTANCE) / 1000) * 1000) / 1000,
      poc: COLLISION_PROBABILITY
    })
  );
};
