import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";

const Googlemap = () => {
  const location = {
    lat: 37.555830800836425,
    lng: 127.04938530921937
  };
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      language="en"
      libraries={["places"]}
    >
      <GoogleMap
        mapContainerStyle={{
          width: "30rem",
          height: "20rem"
        }}
        center={location}
        zoom={2}
        options={{
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }]
            }
          ]
        }}
      >
        <MarkerF position={location} />
      </GoogleMap>
    </LoadScript>
  );
};

export default Googlemap;
