import React from "react";
import PageTemplate from "@/components/ui/PageTemplate";
import { Layout, Title, SubContainer } from "./TeamStyle";
import OurTeam from "@/components/company/OurTeam";
import Advisors from "@/components/company/Advisors";
import useObserver from "@/hooks/page/useObserver";

const Team = () => {
  useObserver("content10");

  return (
    <PageTemplate id="Team" backgroundColor={"#ffffff"}>
      <Layout id="content10">
        <Title>Our Team</Title>
        <SubContainer>
          <OurTeam />
        </SubContainer>
        <Title>Our Advisors</Title>
        <SubContainer>
          <Advisors />
        </SubContainer>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Team);
