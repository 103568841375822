import { isMobile } from "react-device-detect";

export const INITIAL_VIEW = { calendar: "dayGridMonth", list: "listMonth" };
export const HEADER_TOOLBAR = {
  calendar: {
    left: "timeGridWeek,dayGridMonth",
    center: "title",
    right: isMobile ? "prev,next" : "today prev,next"
  },
  list: {
    left: "",
    center: "title",
    right: isMobile ? "prev,next" : "today prev,next"
  }
};
export const HEIGHT = {
  calendar: "100%",
  list: "100%"
};

export const DESCRIPTION_FIELD = [
  "Host/Organization",
  "Host/Organization Contact",
  "Link",
  "Posting Author",
  "Posting Author Email",
  "Posting Author Phone",
  "Speakers",
  "Location(EN)",
  "Location(KR)",
  "Description"
];

export const ACCESS_TOKEN = "accessToken";

export const CALENDAR_TYPE = [
  { label: "Calendar", value: "calendar" },
  { label: "List", value: "list" }
];

export const EVENT_CALENDAR_URL = "/spacecalendar/events";
export const EVENT_SEARCH_URL = "/spacecalendar/events/search";
export const EVENT_DETAIL_URL = "/spacecalendar/events/detail";
export const EVENT_EDIT_URL = "/spacecalendar/events/edit";
export const EVENT_ADD_URL = "/spacecalendar/events/add";
export const CONJUNCTION_CALENDAR_URL = "/spacecalendar/conjunctions";
export const FAQ_URL = "/spacecalendar/faq";

export const LOCALSTORAGE_KEY = "goto-Contact";

export const DESC_KEYS = {
  description: "Description",
  speakers: "Speakers",
  organization: "Host/Organization",
  organizationContact: "Host/Organization Contact",
  postingAuthor: "Posting Author",
  postingAuthorEmail: "Posting Author Email",
  postingAuthorPhone: "Posting Author Phone",
  location_en: "Location(EN)",
  location_kr: "Location(KR)",
  link: "Link"
};

export const SELECT_TYPE = ["GLOBAL", "KOREA"];

export const CALENDAR_ITEM_COLOR = {
  GLOBAL: "skyblue",
  KOREA: "#fccb16"
};

export const calendarIdMap = {
  GLOBAL: process.env.REACT_APP_GOOGLE_CALENDAR_GLOBAL_ID,
  KOREA: process.env.REACT_APP_GOOGLE_CALENDAR_KOREA_ID
};
