import { useCallback, useMemo, useState } from "react";
import { generateTickValues } from "./ConjunctionCalendarComponent-utils";

export const useGetTimeValues = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const after3Days = new Date(today);
  after3Days.setDate(today.getDate() + 4);

  const tickValues = generateTickValues(today, 4, 96);
  const dayValues = generateTickValues(today, 24, 96);

  return { today, after3Days, tickValues, dayValues };
};

export const useChartInteraction = () => {
  const [nodeId, setNodeId] = useState(null);
  const handleMouseMove = useCallback(node => setNodeId(node.id), [setNodeId]);
  const handleMouseLeave = useCallback(() => setNodeId(null), [setNodeId]);
  const handleNodeClick = node => {
    handleMouseMove(node);
    const url = `https://platform.spacemap42.com/conjunction?id=${node.data.CDM_ID}`;
    window.open(url, "_blank");
  };
  const getNodeSize = useMemo(
    () => node => {
      if (nodeId !== null && nodeId === node.id) return 30;
      return 10;
    },
    [nodeId]
  );

  return {
    nodeId,
    handleMouseMove,
    handleMouseLeave,
    handleNodeClick,
    getNodeSize
  };
};
