import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Route, Routes, useLocation } from "react-router-dom";
import Search from "@/components/calendar/Search";
import Detail from "@/components/calendar/Detail";
import EventCalendar from "@/components/calendar/EventCalendar";
import Add from "@/components/calendar/Add";
import CalendarEditPage from "@/page/calendar/edit";
import { LoadScript } from "@react-google-maps/api";
import { isMobile } from "react-device-detect";
import MobileMenu from "@/components/features/Menu/MobileMenu";
import Menu from "@/components/features/Menu/Menu";
import ConjunctionCalendar from "@/components/features/ConjunctionCalendar/ConjunctionCalendar";
import Faq from "@/components/features/Faq/Faq";
import { EVENT_CALENDAR_URL } from "@/constants/calendar";

const SUBROUTES = [
  { path: "events", component: EventCalendar },
  { path: "events/search", component: Search },
  { path: "events/detail", component: Detail },
  { path: "events/add", component: Add },
  { path: "events/edit", component: CalendarEditPage },
  { path: "conjunctions", component: ConjunctionCalendar },
  { path: "faq", component: Faq }
];

const SpaceCalendar = () => {
  const location = useLocation();
  const [language, setLanguage] = useState("en");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { language } = navigator;
    const _language = new Intl.Locale(language).language;
    setLanguage(_language);
    setLoading(true);
  }, []);

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === "/spacecalendar" || pathname === "/spacecalendar/")
      window.open(EVENT_CALENDAR_URL, "_self");
    else if (pathname.split("/")[2] === "detail") {
      window.open(`${EVENT_CALENDAR_URL}/detail${location.search}`, "_self");
    }
  }, [location]);

  return (
    loading && (
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        language={language}
        libraries={["places"]}
      >
        {isMobile ? <MobileMenu /> : <Menu />}
        <SpaceCalendarPage $ismobile={isMobile}>
          <Routes>
            {SUBROUTES.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </SpaceCalendarPage>
      </LoadScript>
    )
  );
};

export default SpaceCalendar;

const SpaceCalendarPage = styled.main`
  width: calc(100dvw - 20rem);
  height: 100dvh;

  box-sizing: border-box;
  margin-left: 20rem;
  background-color: #ffffff;

  ${({ $ismobile }) =>
    $ismobile &&
    css`
      width: 100dvw;
      height: 100dvh;

      margin-left: 0;
      padding: 0;
      padding-top: 7rem;
    `}

  @media screen and (max-width: 700px) {
    //전체 글꼴 키우기
    font-size: 1.5rem;
    :nth-child(n) {
      font-size: inherit;
    }
  }
`;
