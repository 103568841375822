import { SELECT_TYPE } from "@/constants/calendar";
import { useQuerySearchCalendar } from "@/query/calendar/use-query-search-calendar";
import useCalendarStore from "@/store/calendarStore";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearchResults = () => {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("q") || "";
  const startDate = searchParams.get("start") || "";
  const endDate = searchParams.get("end") || "";

  const searchQuery = {
    keyword,
    startDate,
    endDate
  };

  const { isLoading, searchEvents, checkedEvent } = useCalendarStore();
  const events = useMemo(
    () => (isLoading ? [] : searchEvents(searchQuery, checkedEvent)),
    [searchQuery, checkedEvent, searchEvents, isLoading]
  );

  return {
    events,
    isLoading
  };
};
