import styled from "styled-components";
import { IoIosClose } from "react-icons/io";

const Speakers = ({ speakers, setSpeakers }) => {
  const removeSpeaker = name => {
    setSpeakers(
      speakers.filter(speaker => {
        return speaker !== name;
      })
    );
  };

  return (
    <Wrapper>
      {speakers.map((name, index) => {
        return (
          <Items key={index}>
            {name}
            <IoIosClose
              className="close-icon"
              onClick={() => removeSpeaker(name)}
            />
          </Items>
        );
      })}
    </Wrapper>
  );
};

export default Speakers;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  overflow-x: auto;
`;

const Items = styled.div`
  position: relative;
  padding: 0.2rem 0.5rem;
  padding-right: 0.3rem;
  border-radius: 2rem;

  white-space: nowrap;
  font-size: 0.7rem;
  font-weight: 500;
  background-color: #fccb1660;

  display: flex;
  align-items: center;
  gap: 0.3rem;

  .close-icon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
`;
