import styled, { css } from "styled-components";
import { OPTION_LIST } from "@/constants/context";

const ConjunctionSelect = ({ selected, setSelected, type }) => {
  return (
    <Select
      $type={type}
      value={selected}
      onChange={e => {
        setSelected(e.target.value);
      }}
    >
      {OPTION_LIST.map((item, index) => {
        return (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        );
      })}
    </Select>
  );
};

export default ConjunctionSelect;

const Select = styled.select<{ $type: string }>`
  width: 6.5rem;
  height: 2rem;

  border: 1px solid #fccb16;
  border-radius: 5px;
  background-color: transparent;

  color: #fccb16;
  font-weight: bold;
  text-align: center;

  font-size: 1rem;
  outline: none;

  option {
    color: #000000;
    border-radius: 0;
  }

  ${({ $type }) =>
    $type === "CALENDAR" &&
    css`
      width: 7.5rem;
      height: 2rem;
      margin-top: 1rem;
      border: 1px solid #000000;
      color: #000000;
      @media screen and (max-width: 700px) {
        width: 12rem;
        height: 3rem;
      }
    `}
`;
