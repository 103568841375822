import React from "react";
import useWindowSize from "@/hooks/page/useWindowSize";
import PageTemplate from "@/components/ui/PageTemplate";
import { PLATFORM_URL } from "@/constants/contact";
import { HOME_CONTEXT, CONJUNCTION_TABLE_CONTEXT } from "@/constants/context";
import {
  Layout,
  Title,
  DescriptionSection,
  Description,
  Button,
  VideoWrapper,
  Video,
  Content,
  TableWrapper
} from "./HomeStyle";
import ConjunctionTable from "@/components/astro-one/conjunction-table/body/ConjunctionTable";
import { SearchType } from "@/components/astro-one/conjunction-table/header/Search";
import useObserver from "@/hooks/page/useObserver";

const Home = () => {
  const { width, height } = useWindowSize();
  const isUnder700px = width <= 700;
  useObserver("content0");

  return (
    <PageTemplate id="Home">
      <Layout id="content0">
        <Content>
          <Title>
            <img
              src="/logo/main-top-copy.png"
              alt="logo"
              style={{ width: "33rem" }}
            />
          </Title>
          <DescriptionSection>
            {HOME_CONTEXT.DESCRIPTION.map((item, index) => {
              return <Description key={index}>{item}</Description>;
            })}
            <Button>
              <a href={PLATFORM_URL} target="blank">
                Learn More
              </a>
            </Button>
          </DescriptionSection>
        </Content>
        <Content>
          <TableWrapper>
            <ConjunctionTable
              queryKey={SearchType.SEARCH}
              limit={isUnder700px ? 5 : 10}
              totalPage={isUnder700px ? 20 : 10}
              title={CONJUNCTION_TABLE_CONTEXT.TITLE}
              description={CONJUNCTION_TABLE_CONTEXT.DESCRIPTION}
            />
          </TableWrapper>
        </Content>
      </Layout>
      <VideoWrapper $height={height}>
        <Video muted autoPlay loop>
          <source
            alt="Video-P1-Landing-page"
            src="/video/Video-P1-Landing-page.mp4"
            type="video/mp4"
          />
        </Video>
      </VideoWrapper>
    </PageTemplate>
  );
};

export default React.memo(Home);
