import React, { useEffect, useRef } from "react";
import Home from "./Home";
import Astro1 from "./product/Astro1";
import AstroOrca from "./product/AstroOrca";
import AstroLibrary from "./product/AstroLibrary";
import STAI from "./technology/STAI";
import Voronoi from "./technology/Voronoi";
import Resources from "./technology/Resources";
import About from "./company/About";
import Mission from "./company/Mission";
import Team from "./company/Team";
import News from "./news/News";
import Contact from "./company/Contact";
import styled from "styled-components";
import SpaceTalk from "./product/SpaceTalk";

const MobileBody = () => {
  return (
    <PageContainer>
      <Home />
      <SpaceTalk />
      <Astro1 />
      <AstroOrca />
      <AstroLibrary />
      <STAI />
      <Voronoi />
      <Resources />
      <News />
      <About />
      <Mission />
      <Team />
      <Contact />
    </PageContainer>
  );
};

export default React.memo(MobileBody);

const PageContainer = styled.main`
  min-width: 700px;
  height: auto;
  background-color: #ffffff;
`;
