import { PALETTE } from "@/styles/theme";
import React from "react";
import styled from "styled-components";

type PaginationProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
};

const Pagination: React.FC<PaginationProps> = ({
  page,
  setPage,
  totalPages
}) => {
  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };

  const handleFirstPage = () => {
    setPage(0);
  };

  const handleLastPage = () => {
    setPage(totalPages - 1);
  };

  return (
    <PaginationWrapper>
      <button onClick={handleFirstPage} disabled={page === 0}>
        {"<<"}
      </button>
      <button onClick={handlePreviousPage} disabled={page === 0}>
        {"<"}
      </button>
      <strong className="page-text">
        <span className="page-count">{totalPages === 0 ? 0 : page + 1}</span> of{" "}
        {totalPages}
      </strong>
      <button
        onClick={handleNextPage}
        disabled={page === totalPages - 1 || totalPages === 0}
      >
        {">"}
      </button>
      <button
        onClick={handleLastPage}
        disabled={page === totalPages - 1 || totalPages === 0}
      >
        {">>"}
      </button>
    </PaginationWrapper>
  );
};

export default Pagination;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  gap: 10px;
  button {
    background-color: ${PALETTE.grey.default}60;
    border: none;
    border-radius: 5px;
    color: ${PALETTE.grey.light200};
    :hover {
      cursor: pointer;
    }
  }
  .page-text {
    color: ${PALETTE.white};
    .page-count {
      color: ${PALETTE.maze.default};
    }
  }
  .page-size-select {
    position: absolute;
    font-size: 0.7rem;
    color: ${PALETTE.white};
    right: 0;
    width: 5.3rem;
    padding: 2px 7px;
    background-color: ${PALETTE.grey.default}60;
    border: none;
    border-radius: 5px;
    color: ${PALETTE.grey.light200};
    option {
      background-color: ${PALETTE.grey.dark300}${PALETTE.opacity[70]};
    }
    :active {
      border: none;
      background-color: ${PALETTE.grey.default}60;
    }
  }
`;
