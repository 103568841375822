import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

const SpaceCalendarLogo = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/spacecalendar");
  };

  return (
    <Wrapper onClick={onClick}>
      <LogoImg alt="spacemap" src="/logo/maintype_3.png" $ismobile={isMobile} />
      <Text $ismobile={isMobile}>Space Calendar</Text>
    </Wrapper>
  );
};

export default SpaceCalendarLogo;

const Wrapper = styled.section`
  position: relative;
  padding-bottom: 1rem;
  cursor: pointer;
`;

const LogoImg = styled.img<{ $ismobile: boolean }>`
  width: ${({ $ismobile }) => ($ismobile ? "17rem" : "13rem")};
  cursor: pointer;
`;

const Text = styled.div<{ $ismobile: boolean }>`
  position: absolute;
  top: 1.5rem;
  width: 13rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fccb16;
  ${({ $ismobile }) =>
    $ismobile &&
    css`
      top: 2rem;
      width: 17rem;
      font-size: 1.5rem;
    `}
`;
