import { useForm, useWatch, Controller } from "react-hook-form";
import styled from "styled-components";

import { StandaloneSearchBox } from "@react-google-maps/api";
import TableLoadingSpinner from "../../ui/table/TableLoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import TimezoneSelect from "react-timezone-select";
import { EVENT_CALENDAR_URL, DESCRIPTION_FIELD } from "@/constants/calendar";
import useCalendarStore, {
  CalendarEvent,
  CalendarTime
} from "@/store/calendarStore";
import { FaPlus } from "react-icons/fa";

import { isMobile } from "react-device-detect";
import {
  useEditEvents,
  useEditFiles,
  useSearchAddress
} from "./EventEditForm.hooks";
import { getFormattedDate, makeEventForm } from "./EventEditForm.utils";
import Speakers from "../Speakers";
import _ from "lodash";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";

const EventEditForm = ({
  event,
  calendarType
}: {
  event: CalendarEvent;
  calendarType: string;
}) => {
  const { timezone: defaultTimeZone, setTimezone } = useCalendarStore();

  const initialValues = useMemo(() => {
    const start = getFormattedDate(event.start, "start");
    const end = getFormattedDate(event.end, "end");

    return {
      ...event,
      start,
      end,
      isAllday: !!event.start.date,
      timeZone: event.start.timeZone || defaultTimeZone,
      description: {
        ...event.description,
        speakers: ""
      }
    };
  }, [event, defaultTimeZone]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: { ...initialValues }
  });
  const navigate = useNavigate();

  // const formValues = useWatch({
  //   control,
  //   name: ["start", "isAllday"]
  // });

  const isAllday = watch("isAllday");
  const startDate = watch("start");

  const { updateEvent, loading } = useEditEvents(event.id, calendarType);

  const {
    fetchedDocumentUrl,
    flyerRef,
    documentRef,
    flyer,
    previewUrl,
    document,
    uploadFlyer,
    handleFlyerChange,
    uploadDocument,
    handleDocumentChange,
    handleDeleteFlyer,
    handleUpdateFiles,
    handleDocumentDelete
  } = useEditFiles(event.id);

  const {
    onLoad,
    onPlacesChanged,
    inputRef: locationInputRef,
    address
  } = useSearchAddress();

  const [speakers, setSpeakers] = useState<string[]>(
    event?.description.speakers
  );
  const queryClient = useQueryClient();

  const onSubmit = async data => {
    try {
      const dataWithLocation = {
        ...data,
        description: {
          ...data.description,
          location_en: address.en,
          location_kr: address.ko
        }
      };
      const event = makeEventForm(dataWithLocation, speakers);
      await Promise.all([handleUpdateFiles(), updateEvent(event)]);

      alert(`Update Success!`);
      queryClient.invalidateQueries(["calendar"]);
      navigate(EVENT_CALENDAR_URL);
      // TODO: revalidate the event
    } catch (error) {
      alert(`Update Failed!`);
      console.error(error);
    }
  };

  const handleStartTimeChange = e => {
    const startDateValue = e.target.value;
    const endDateValue = watch("end");

    const startDate = new Date(startDateValue);
    const endDate = new Date(endDateValue);
    if (!!endDateValue && startDate.getTime() <= endDate.getTime()) return;

    if (isAllday) {
      setValue("end", startDateValue);
      return;
    }

    const date = new Date(startDate);

    date.setTime(date.getTime() + 10 * 60 * 60 * 1000);

    setValue("end", date.toISOString().slice(0, 16));
  };

  const addSpeaker = e => {
    let havetoAdd = false;
    if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
      e.preventDefault();
      havetoAdd = true;
    } else if (e._reactName === "onClick") {
      e.stopPropagation();
      havetoAdd = true;
    }

    const speaker = watch("description.speakers");
    if (!havetoAdd || !speaker) return;

    setValue("description.speakers", "");
    if (!speakers.includes(speaker)) {
      setSpeakers(prev => [...prev, speaker]);
    }
  };

  return (
    <Section $isloading={loading}>
      {loading && (
        <SpinnerSection>
          <TableLoadingSpinner width={"3rem"} height={"3rem"} />
        </SpinnerSection>
      )}
      <Wrapper $ismobile={isMobile}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {isMobile ? (
            <></>
          ) : (
            <CloseBtn
              src="/svg/close-black.svg"
              onClick={() => navigate(EVENT_CALENDAR_URL)}
            />
          )}
          <InputSection>
            <InputItem>
              <Label>
                Title
                <Required>*</Required>
              </Label>
              <Input
                type="text"
                placeholder="Enter Title"
                {...register("summary", { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>
                Schedule
                <Required>*</Required>
              </Label>
              <InputField>
                <AlldayCheckBox>
                  <input
                    type="checkbox"
                    {...register("isAllday", {
                      onChange: () => {
                        setValue("start", "");
                        setValue("end", "");
                      }
                    })}
                  />
                  All Day
                </AlldayCheckBox>
                {!isAllday && (
                  <Controller
                    name="timeZone"
                    control={control}
                    render={({ field }) => (
                      <TimezoneSelect
                        value={field.value}
                        onChange={field.onChange}
                        styles={{
                          control: base => ({
                            ...base,
                            width: isMobile ? "20rem" : "12rem",
                            height: "2.5rem",
                            textAlign: "center"
                            // borderRadius: "2rem"
                            // backgroundColor: "#e9e9e9"
                          }),
                          indicatorSeparator: base => ({
                            ...base,
                            display: "none"
                          })
                        }}
                      />
                    )}
                  />
                )}
              </InputField>
            </InputItem>
            <InputItem>
              <InputField>
                <DateInput
                  type={isAllday ? "date" : "datetime-local"}
                  {...register("start", {
                    required: true,
                    onChange: e => handleStartTimeChange(e)
                  })}
                />
                <span>~</span>
                <DateInput
                  type={isAllday ? "date" : "datetime-local"}
                  {...register("end", {
                    required: true,
                    min: startDate
                  })}
                  min={startDate}
                />
              </InputField>
            </InputItem>
            <InputItem>
              <Label>
                Location
                <Required>*</Required>
              </Label>
              <StandaloneSearchBox
                onLoad={onLoad}
                onPlacesChanged={onPlacesChanged}
              >
                <Input
                  ref={locationInputRef}
                  type="text"
                  placeholder="Enter Location"
                  {...register("location", { required: true })}
                />
              </StandaloneSearchBox>
            </InputItem>
            <InputItem>
              <Label>
                Host/Organization
                <Required>*</Required>
              </Label>
              <Input
                type="text"
                placeholder="Enter Name"
                {...register("description.organization", { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>Host/Organization Contact</Label>
              <Input
                type="text"
                placeholder="Enter Contact"
                {...register("description.organizationContact")}
              />
            </InputItem>
            <InputItem>
              <Label>
                Link
                <Required>*</Required>
              </Label>
              <Input
                type="text"
                placeholder="Enter Link"
                {...register("description.link", { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>
                Posting Author
                <Required>*</Required>
              </Label>
              <Input
                type="text"
                placeholder="Enter Name"
                {...register("description.postingAuthor", { required: true })}
              />
            </InputItem>
            <InputItem>
              <Label>
                Posting Author Email
                <Required>*</Required>
              </Label>
              <Input
                type="email"
                placeholder="Enter Email"
                {...register("description.postingAuthorEmail", {
                  required: true
                })}
              />
            </InputItem>
            <InputItem>
              <Label>Posting Author Phone</Label>
              <Input
                type="tel"
                placeholder="Enter Phone Number"
                {...register("description.postingAuthorPhone")}
              />
            </InputItem>
            <InputItem>
              <Label>
                Speakers
                <Speakers speakers={speakers} setSpeakers={setSpeakers} />
              </Label>
              <Input
                type="text"
                placeholder="Enter Speakers"
                onKeyDown={e => addSpeaker(e)}
                {...register("description.speakers")}
              />
              <PlusBtn onClick={addSpeaker}>
                <FaPlus />
              </PlusBtn>
            </InputItem>
            <InputItem>
              <Label>Files</Label>
              <AddDocument
                onClick={() => {
                  if (document) return;
                  uploadDocument();
                }}
              >
                {document ? (
                  <>
                    <a
                      href={fetchedDocumentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {document.name}
                    </a>
                    <DeleteIcon
                      src="/svg/delete-trash.svg"
                      alt="delete"
                      onClick={handleDocumentDelete}
                    />
                  </>
                ) : (
                  <>
                    <Icon src="/svg/file.svg" />
                    <span>Add Document</span>
                  </>
                )}
              </AddDocument>
              <FlyerSection onClick={uploadFlyer}>
                {previewUrl ? (
                  <>
                    <DeleteBtn onClick={handleDeleteFlyer}>
                      <Icon src="/svg/delete-trash.svg" alt="delete" />
                      Delete
                    </DeleteBtn>
                    <Flyer src={previewUrl} alt="Preview" />
                  </>
                ) : (
                  <AddFlyer>
                    <Icon src="/svg/file.svg" />
                    Add Flyer
                  </AddFlyer>
                )}
                <FileInput
                  type="file"
                  onChange={handleFlyerChange}
                  ref={flyerRef}
                />
              </FlyerSection>
              <FileInput
                type="file"
                ref={documentRef}
                onChange={handleDocumentChange}
              />
            </InputItem>
            <InputItem>
              <Label>Description</Label>
              <TextArea rows={3} {...register("description.description")} />
            </InputItem>
            <SubmitButton type="submit">Edit</SubmitButton>
          </InputSection>
        </Form>
      </Wrapper>
    </Section>
  );
};

export default EventEditForm;

const SpinnerSection = styled.section`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const Section = styled.section<{ $isloading?: boolean }>`
  width: 100%;
  position: relative;
  height: 100dvh;
  display: flex;
  align-items: start;
  overflow-y: ${({ $isloading }) => ($isloading ? "hidden" : "auto")};
  padding-bottom: 2rem;
  z-index: 2;
`;

export const Wrapper = styled.div<{ $ismobile: boolean }>`
  width: ${({ $ismobile }) => ($ismobile ? "100%" : "80%")};
  padding-top: 2rem;
  margin: 0 auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FlyerSection = styled.div`
  position: relative;
  width: 100%;
  height: 20rem;
  background-color: #e9e9e9;
  border-radius: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const AddFlyer = styled.section`
  position: absolute;
  padding: 0.2rem 0.5rem;
  top: 50%;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-weight: 700;
  transform: translate(-50%, -50%);
  filter: invert(0.5);
`;

export const InputSection = styled.section`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DeleteBtn = styled.section`
  position: absolute;
  padding: 0.2rem 0.5rem;
  top: 100%;
  right: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;
`;

const Icon = styled.img`
  width: 1rem;
  height: 1rem;
  filter: invert(1);
`;

export const InputItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.5rem;
`;

const Label = styled.span`
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

export const RadioBtn = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const InputField = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
`;

const SubmitButton = styled.button`
  box-sizing: border-box;
  height: 2.5rem;
  min-height: 38px;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  background-color: #001326;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #fccb16;
  }
`;

export const CloseBtn = styled.img`
  position: absolute;
  top: 0.5rem;
  right: 1rem;

  width: 1.5rem;
  height: 1.5rem;

  cursor: pointer;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  min-height: 38px;
  padding-left: 1rem;
  border: 0;
  background-color: #e9e9e9;
  border-radius: 2rem;
  font-size: 1rem;
  font-family: inherit;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #e9e9e9 inset !important;
    background-clip: text;
  }

  @media screen and (max-width: 700px) {
    padding-left: 2rem;
  }
`;

const DateInput = styled(Input)`
  text-align: center;
  padding-right: 1rem;
`;

const FileInput = styled(Input)`
  display: none;
`;

export const Flyer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

const AddDocument = styled.section`
  font-family: inherit;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  pointer-events: auto;

  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  min-height: 38px;
  padding-left: 1rem;
  border: 0;
  background-color: #e9e9e9;
  border-radius: 2rem;
  > span {
    filter: invert(0.5);
  }

  @media screen and (max-width: 700px) {
    padding-left: 2rem;
  }
`;

const DeleteIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Required = styled.span`
  color: red;
  padding-left: 2px;
  font-weight: 700;
`;

const TextArea = styled.textarea`
  font-family: inherit;
  min-height: 5rem;
  box-sizing: border-box;
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 1rem;
  border: 0;
  background-color: #e9e9e9;
  border-radius: 1rem;
  font-size: 1rem;

  resize: none;

  @media screen and (max-width: 700px) {
    padding-left: 2rem;
  }
`;

const PlusBtn = styled.div`
  position: absolute;
  bottom: 0.25rem;
  right: 1rem;

  width: 3rem;
  height: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2rem;
  background-color: #ffffff;
  filter: invert(1);
  cursor: pointer;

  //TimezoneSelect가 38px보다 작아지지 않기 때문에, 이에 따른 위치 조정ㄴ
  @media all and (max-width: 1499px) {
    bottom: 9.5px;
  }
`;

const AlldayCheckBox = styled.div`
  height: 2.5rem;
  min-height: 38px;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  input {
    font-size: 1rem;
    margin-left: 0;
  }
`;
