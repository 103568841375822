import React, { useEffect, useState, useRef } from "react";
import useWindowSize from "@/hooks/page/useWindowSize";
import Articles from "@/components/news/Articles";
import { NUM_NEWS } from "@/constants/news";
import { onWheel } from "../../components/news/eventHandler";
import {
  NewsPageTemplate,
  Layout,
  NewsPageTitleWrapper,
  NewsPageTitle,
  ChevronUp,
  ChevronDown,
  ArticleViewer
} from "./NewsStyle";
import useObserver from "@/hooks/page/useObserver";

const News = () => {
  const { width, height } = useWindowSize();
  const [newsIndex, setIndex] = useState(0);
  const isScrolling = useRef(false);
  useObserver("content7");

  useEffect(() => {
    const initializeNews = event => {
      const index = event.detail.index;
      index === 7 && setIndex(0);
    };

    document.addEventListener("onChangeMenu", initializeNews);

    return () => {
      document.removeEventListener("onChangeMenu", initializeNews);
    };
  }, []);

  return (
    <NewsPageTemplate
      id="Press & Article"
      $width={width}
      $height={height}
      $backgroundcolor={"#ffffff"}
    >
      <Layout id="content7">
        <NewsPageTitleWrapper>
          <NewsPageTitle>
            How <strong>SPACEMAP</strong> is recognized
          </NewsPageTitle>
        </NewsPageTitleWrapper>
        {/* <ChevronUp
          onClick={() => {
            setIndex(newsIndex - 1);
          }}
          src={"/svg/chevron-up.svg"}
          alt="chevron"
          $index={newsIndex}
        /> */}
        {/* <ArticleViewer
          onWheel={e => onWheel(e, isScrolling, newsIndex, setIndex)}
        >
          <Articles newsIndex={newsIndex} />
          </ArticleViewer> */}
        <Articles newsIndex={newsIndex} />
        {/* <ChevronDown
          onClick={() => {
            setIndex(newsIndex + 1);
          }}
          src={"/svg/chevron-down.svg"}
          alt="chevron"
          $index={newsIndex}
          $last={NUM_NEWS - 2}
        /> */}
      </Layout>
    </NewsPageTemplate>
  );
};

export default React.memo(News);
