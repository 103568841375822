import EventForm from "./EventForm/EventForm";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Add = () => {
  return (
    <Layout $ismobile={isMobile}>
      <EventForm />
    </Layout>
  );
};

export default Add;

const Layout = styled.section<{ $ismobile: boolean }>`
  position: relative;
  width: ${({ $ismobile }) => ($ismobile ? "100%" : "80%")};
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`;
