import { PALETTE } from '@/styles/theme';
import styled from 'styled-components';

const Tooltip = ({ children, message }) => {
  return (
    <Container>
      {children}
      <div className="tooltip">{message}</div>
    </Container>
  );
};

export default Tooltip;

const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: inline-block;

  &:hover > .tooltip,
  &:active > .tooltip {
    display: block;
  }

  .tooltip {
    white-space: pre-line;
    display: none;
    position: absolute;
    top: 100%; /* Change from bottom: 0; to top: 100%; */
    left: 50%;
    background-color: ${PALETTE.grey.dark100};
    border-width: 0px;
    border-radius: 5px;
    color: ${PALETTE.white};
    font-size: 12px;
    font-weight: 500;
    height: auto;
    letter-spacing: -0.25px;
    margin-top: 6.8px;
    padding: 5px 11px;
    width: max-content;
    z-index: 1000000;
    transform: translate(
      -44%,
      -230%
    ); /* Change from transform: translate(-44%, -110%); to transform: translate(-44%, 0%); */
  }

  .tooltip::after {
    border-color: ${PALETTE.grey.dark100} transparent;
    border-style: solid;
    border-width: 8px 6px 0 6.5px; /* Change from border-width: 0 6px 8px 6.5px; to border-width: 8px 6px 0 6.5px; */
    content: '';
    display: block;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: -7px; /* Change from top: -7px; to bottom: -7px; */
    width: 0;
    z-index: 1;
  }

  .tooltip::before {
    border-color: ${PALETTE.grey.dark100} transparent;
    border-style: solid;
    border-width: 8px 6px 0 6.5px; /* Change from border-width: 0 6px 8px 6.5px; to border-width: 8px 6px 0 6.5px; */
    content: '';
    display: block;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: -8px; /* Change from top: -8px; to bottom: -8px; */
    width: 0;
    z-index: 0;
  }
`;
