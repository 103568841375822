import styled from "styled-components";

const colors = [
  { r: 255, g: 0, b: 0 },
  { r: 255, g: 165, b: 0 },
  { r: 255, g: 255, b: 0 },
  { r: 0, g: 128, b: 0 },
  { r: 0, g: 255, b: 255 },
  { r: 0, g: 0, b: 255 },
  { r: 0, g: 0, b: 130 }
];

const GradientBar = () => {
  const PoCLabel = ["1", "1e-2", "1e-4", "1e-6~"];
  const barSteps = 200;
  const steps = Array.from(
    { length: barSteps + 1 },
    (_, index) => index / barSteps
  );

  const interpolateColor = (startColor, endColor, ratio) => {
    const r = Math.round(startColor.r + (endColor.r - startColor.r) * ratio);
    const g = Math.round(startColor.g + (endColor.g - startColor.g) * ratio);
    const b = Math.round(startColor.b + (endColor.b - startColor.b) * ratio);
    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <>
      {PoCLabel.map((poc, index) => {
        return (
          <PoCValue key={index} $index={index}>
            {poc}
          </PoCValue>
        );
      })}
      <BarLabel>Probability</BarLabel>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          height: "1rem",
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "5px",
          boxSizing: "border-box",
          overflow: "hidden"
        }}
      >
        {steps.map(step => {
          const colorIndex = step * (colors.length - 1);
          const startIndex = Math.floor(colorIndex);
          const endIndex = Math.ceil(colorIndex);

          if (startIndex === endIndex) {
            return (
              <div
                key={step}
                style={{
                  flex: "1",
                  height: "100%",
                  background: interpolateColor(
                    colors[startIndex],
                    colors[endIndex],
                    0
                  )
                }}
              ></div>
            );
          }

          const startColor = colors[startIndex];
          const endColor = colors[endIndex];
          const ratio = colorIndex - startIndex;

          return (
            <div
              key={step}
              style={{
                flex: "1",
                height: "100%",
                background: interpolateColor(startColor, endColor, ratio)
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default GradientBar;

const Text = styled.div`
  position: absolute;
  font-size: 0.8rem;
  font-weight: 500;
  white-space: nowrap;
  transform: translateX(-50%);
`;

const PoCValue = styled(Text)<{ $index: number }>`
  top: 100%;
  left: ${({ $index }) => `calc(${$index * 33.3333}%)`};
`;

const BarLabel = styled(Text)`
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
`;
