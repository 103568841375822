import PageTemplate, {
  Description,
  Emphasize,
  PageLayout,
  Title
} from "@/components/ui/PageTemplate";
import { SPACETALK_CONTEXT } from "@/constants/context";
import useObserver from "@/hooks/page/useObserver";
import styled from "styled-components";

const SpaceTalk = () => {
  useObserver("42-Talks-Content");
  return (
    <PageTemplate id="42 Talks" backgroundColor={""}>
      <Layout id="42-Talks-Content">
        <SpaceTalkTitle>
          <strong>
            42 <Emphasize>T</Emphasize>alks
          </strong>
          ,<br />
          <SubTitle>{SPACETALK_CONTEXT.SUBTITLE}</SubTitle>
        </SpaceTalkTitle>
        <ContentWrapper>
          <ImgWrapper>
            <Img src="/services/42Talks.png" alt="42 Talks" />
          </ImgWrapper>
          <DescriptionWrapper>
            {SPACETALK_CONTEXT.DESCRIPTION.map((description, index) => {
              return <Description key={index}>{description}</Description>;
            })}
          </DescriptionWrapper>
        </ContentWrapper>
      </Layout>
    </PageTemplate>
  );
};

export default SpaceTalk;

const Layout = styled(PageLayout)`
  flex-direction: column;
  gap: 2rem;
`;

const SpaceTalkTitle = styled(Title)`
  line-height: 2rem;
`;

const SubTitle = styled.div`
  font-size: 1.6rem;
`;

const ImgWrapper = styled.section`
  flex: 1;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.section`
  display: flex;
  gap: 2rem;
`;

const DescriptionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
