import React from "react";
import STAIImg from "@/components/STAIImg";
import PageTemplate, { SubTitle } from "@/components/ui/PageTemplate";
import {
  Layout,
  DesriptionWrapper,
  STAITitle,
  Description,
  ImgWrapper
} from "./STAIStyle";
import { STAI_CONTEXT } from "@/constants/context";
import useObserver from "@/hooks/page/useObserver";

const STAI = () => {
  useObserver("content4");

  return (
    <PageTemplate id="Space-Time AI (ST-AI)">
      <Layout id="content4">
        <ImgWrapper>
          <STAIImg />
        </ImgWrapper>
        <DesriptionWrapper>
          <STAITitle>
            <strong> {STAI_CONTEXT.TITLE} </strong>
            <SubTitle>{STAI_CONTEXT.SUBTITLE}</SubTitle>
          </STAITitle>
          <Description>{STAI_CONTEXT.DESCRIPTION}</Description>
        </DesriptionWrapper>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(STAI);
