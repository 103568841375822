import styled from "styled-components";
import ProductNavBar from "./ProductNavBar";
import PageNavBar from "./PageNavBar";
import Logo from "./Logo";

const NavigationBar = () => {
  return (
    <NavBarWrapper>
      <Logo />
      <PageNavBar />
      <ProductNavBar />
    </NavBarWrapper>
  );
};

export default NavigationBar;

const NavBarWrapper = styled.header`
  position: fixed;
  top: 0;
  box-sizing: border-box;

  width: 100%;
  min-width: 700px;
  min-height: 5rem;
  padding: 0 3rem;

  background-color: #000000;
  z-index: 10;

  display: flex;
  align-items: center;
`;
