import React, { useState } from "react";
import styled from "styled-components";

const lookingFor = [
  "for information about SpaceMap",
  "to work with SpaceMap",
  "to invest in SpaceMap",
  "to connect with SpaceMap about another topic"
];

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [lookingForIndex, setLookingForIndex] = useState(0);

  const checkKey = e => {
    if (
      e.key === "PageUp" ||
      e.key === "ArrowUp" ||
      e.key === "PageDown" ||
      e.key === "ArrowDown"
    )
      e.stopPropagation();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = {
      email,
      name,
      message,
      lookingFor: lookingFor[lookingForIndex]
    };

    // Assuming you have set up the endpoint to handle the form data
    const response = await fetch(process.env.REACT_APP_EMAIL_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      },
      mode: "no-cors"
    });

    if (response) {
      alert("Email sent successfully. We will get back to you shortly.");
      resetForm();
    } else {
      alert("Email failed to send. Please try again later.");
    }
  };

  const resetForm = () => {
    setEmail("");
    setName("");
    setMessage("");
    setLookingForIndex(0);
  };

  return (
    <ContactFormWrapper
      id="emailForm"
      className="gform"
      onSubmit={handleSubmit}
    >
      <FormGroup>
        <label htmlFor="name">Name *</label>
        <Input
          type="text"
          className="form-control"
          id="name"
          name="name"
          placeholder="Your Name"
          required
          value={name}
          onChange={e => setName(e.target.value)}
          onKeyDown={e => checkKey(e)}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="email">Email *</label>
        <Input
          type="text"
          className="form-control"
          id="email"
          name="email"
          placeholder="Your Email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
          onKeyDown={e => checkKey(e)}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="lookingFor">Looking For *</label>
        <Select
          className="form-control"
          value={lookingFor[lookingForIndex]}
          onChange={e => {
            const index = lookingFor.indexOf(e.target.value);
            setLookingForIndex(index);
          }}
        >
          {lookingFor.map((item, index) => (
            <option key={index} value={index}>
              {item}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <label htmlFor="message">Message</label>
        <TextArea
          className="form-control"
          id="message"
          name="message"
          rows={5}
          placeholder="Your Message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          onKeyDown={e => checkKey(e)}
        />
      </FormGroup>

      <div className="form-group m-0">
        <Button id="btnSubmit" className="btn-submit mt-2">
          Send
        </Button>
      </div>
    </ContactFormWrapper>
  );
};

export default ContactForm;

const ContactFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-right: 3rem;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

const Input = styled.input`
  width: auto;
  font-family: Kanit;

  &::placeholder {
    color: gray;
    font-family: Kanit;
  }
`;

const TextArea = styled.textarea`
  &::placeholder {
    color: gray;
    font-family: Kanit;
  }
  font-family: Kanit;
`;

const Select = styled.select`
  font-family: Kanit;
`;

const Button = styled.button`
  all: unset;
  width: 7rem;
  height: 2rem;

  box-sizing: border-box;
  border: 0.0625rem solid #000000;
  border-radius: 0.625rem;

  font-size: 1.2rem;
  color: #000000;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;
