import {
  TeamItem,
  ImgWrapper,
  Img,
  Info,
  Name,
  Role
} from "@/page/company/TeamStyle";
import { ADVISORS } from "@/constants/memberList";

const Advisors = () => {
  return (
    <>
      {ADVISORS.map((member, index) => {
        return (
          <TeamItem key={`advisor${index}`}>
            <ImgWrapper>
              <Img src={member.img} alt="member.jpg" />
            </ImgWrapper>
            <Info>
              <Name>{member.name}</Name>
              <Role>{member.role}</Role>
            </Info>
          </TeamItem>
        );
      })}
    </>
  );
};

export default Advisors;
