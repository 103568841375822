import React from "react";
import PageTemplate, {
  Title,
  Content,
  SubTitle
} from "@/components/ui/PageTemplate";
import {
  Layout,
  Container,
  VoronoiTitle,
  DescriptionWrapper,
  VoronoiDescription,
  VideoWrapper,
  Video
} from "./VoronoiStyle";
import { VORONOI_CONTEXT } from "@/constants/context";
import useObserver from "@/hooks/page/useObserver";

const Voronoi = () => {
  useObserver("content5");

  return (
    <PageTemplate id="Voronoi Diagram">
      <Layout id="content5">
        <Container>
          <VideoWrapper>
            <Video muted autoPlay loop>
              <source
                alt="spaceTechnology"
                src="/technology/CA-simulation.mp4"
                type="video/mp4"
              />
            </Video>
          </VideoWrapper>
          <VoronoiTitle>
            <Title>
              <strong> {VORONOI_CONTEXT.TITLE} </strong>
              <SubTitle> {VORONOI_CONTEXT.SUBTITLE} </SubTitle>
            </Title>
            <VoronoiDescription>
              {VORONOI_CONTEXT.DESCRIPTION1}
            </VoronoiDescription>
          </VoronoiTitle>
        </Container>
        <Content>
          <DescriptionWrapper>
            {VORONOI_CONTEXT.DESCRIPTION2.map((item, index) => {
              return (
                <VoronoiDescription key={index}>{item}</VoronoiDescription>
              );
            })}
          </DescriptionWrapper>
        </Content>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Voronoi);
