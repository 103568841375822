import styled from "styled-components";
import { PageLayout } from "@/components/ui/PageTemplate";

export const Layout = styled(PageLayout)`
  align-items: center;
  justify-content: center;
  gap: 5rem;
`;

export const ImgWrapper = styled.section`
  width: 20rem;
  height: 23rem;

  display: flex;
  justify-content: center;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 auto;

  object-fit: cover;
`;

export const DescriptionWrapper = styled.section`
  width: 33rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  gap: 2rem;
`;

export const MainDescription = styled.span`
  font-size: 2.4rem;
  font-weight: 700;
  color: #0070c0;
`;

export const SubDescription = styled.span`
  font-size: 1.2rem;
  font-weight: 300;
  color: #000000;
`;
