import TableLoadingSpinner from "@/components/ui/table/TableLoadingSpinner";

import { useSearchParams } from "react-router-dom";
import SearchResult from "../SearchResult";
import styled from "styled-components";
import { useSearchResults } from "./SearchResults.hooks";
// import useCalendarStore from "@/store/calendarStore";

export const SearchResults = () => {
  const [searchParams] = useSearchParams();
  const { events, isLoading } = useSearchResults();

  return (
    <ResultSection>
      {!searchParams && <Message>Search Event</Message>}

      {searchParams && isLoading && (
        <TableLoadingSpinner width={"3rem"} height={"3rem"} />
      )}

      {searchParams && !isLoading && events.length === 0 && (
        <Message>No Event Found</Message>
      )}

      {searchParams &&
        !isLoading &&
        events.length > 0 &&
        events.map((event, index) => (
          <SearchResult key={index} event={event} />
        ))}
    </ResultSection>
  );
};
const ResultSection = styled.section`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  overflow-y: auto;
  border-top: 1px solid #001326;
`;

const Message = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2rem;
  color: #00000060;
`;
