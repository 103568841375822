import React, { createContext, useContext, useState } from "react";
import styled from "styled-components";
import Pagination from "./Pagination";
import TableLoadingSpinner from "./TableLoadingSpinner";
import { PALETTE } from "@/styles/theme";
import Tooltip from "./Tooltip";

interface TableProps {
  headers: string[];
  columns: string[];
  data: any[];
  page: number;
  limit: number;
  totalPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
}

type TableContextType = {
  headers: string[];
  columns: string[];
  page: number;
  limit: number;
};

const TableContext = createContext<TableContextType>({
  headers: [],
  columns: [],
  page: 0,
  limit: 3
});

const Table: React.FC<TableProps> = ({
  headers,
  columns,
  data,
  page,
  setPage,
  isLoading,
  totalPages,
  limit
}) => {
  return (
    <TableContext.Provider value={{ headers, columns, page, limit }}>
      <Container>
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                {headers.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                data.map((row, rowIndex) => (
                  <TableRow key={rowIndex} index={rowIndex} data={row} />
                ))}
            </tbody>
          </StyledTable>
          {isLoading && (
            <Overlay>
              <TableLoadingSpinner width={"24px"} height={"24px"} />
            </Overlay>
          )}
        </TableContainer>

        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </TableContext.Provider>
  );
};

export default Table;

const TableRow = ({ index, data }: { index: number; data: any }) => {
  const { columns, page, limit } = useContext(TableContext);

  const [$isHover, setIsHover] = useState(false);

  const start = page * limit;

  const onRowClicked = data => {
    window.open(
      `https://platform.spacemap42.com/conjunction?id=${data.CDM_ID}`,
      "_blank"
    );
  };

  return (
    <StyledTableRow
      key={`${limit}${data.pName}`}
      $isHover={$isHover}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => onRowClicked(data)}
    >
      <td>{start + index + 1}</td>
      <td>
        <Tooltip message={data.pId}>
          <ObjectName>{data.pName}</ObjectName>
        </Tooltip>
      </td>
      <td>
        <Tooltip message={data.sId}>
          <ObjectName>{data.sName}</ObjectName>
        </Tooltip>
      </td>
      <td>{data.tcaTime}</td>
      <td>{data.dca}</td>
      <td>{data.poc.toExponential(2)}</td>
    </StyledTableRow>
  );
};

// 스타일 컴포넌트
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 10px;
`;

const TableContainer = styled.div`
  position: relative;
  width: 100%;

  min-height: 444px;
  @media screen and (max-width: 700px) {
    min-height: 200px;
  }
`;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: transparent;
`;

const StyledTable = styled.table`
  width: 100%;
  font-size: 0.7rem;

  border-collapse: separate;
  border-spacing: 0;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  height: 100%;

  background-color: #000000;
  color: ${PALETTE.white};

  th,
  td {
    padding: 0.2rem 0.5rem;
    border-bottom: 1px solid grey;
    font-weight: 700;
    white-space: pre-wrap;

    text-align: center;
  }

  th {
    padding: 0.5rem 0.2rem;
    background-color: ${PALETTE.grey.dark100};
    backdrop-filter: blur(20px);
    font-weight: 900;
    position: sticky;
    top: 0;
  }

  tbody {
    overflow-y: scroll;
  }
`;

type StyledTableRowProps = {
  $isHover: boolean;
};

const StyledTableRow = styled.tr<StyledTableRowProps>`
  transition: background-color 0.3s ease;

  /* padding: 8px; */
  background-color: ${props => (props.$isHover ? "#e6e6e6" : "auto")};
  color: ${props => (props.$isHover ? "black" : "auto")};
  cursor: pointer;
`;

const ObjectName = styled.span`
  :hover {
    color: ${PALETTE.maze.default};
    cursor: pointer;
  }
`;
