import { EVENT_DETAIL_URL } from "@/constants/calendar";
import useCalendarStore, { CalendarEvent } from "@/store/calendarStore";
import { changeDateForm } from "@/utils";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import ColorBox from "../ui/ColorBox";

const SearchResult = ({ event }: { event: CalendarEvent }) => {
  // const { events } = useCalendarStore();
  const navigate = useNavigate();

  const resultclick = id => {
    navigate(`${EVENT_DETAIL_URL}?id=${id}`, { state: "detail" });
  };

  const formattedDate = changeDateForm(event.start, event.end);
  const time = formattedDate.split("/");
  return (
    <Wrapper onClick={() => resultclick(event.id)} $ismobile={isMobile}>
      <Date $ismobile={isMobile}>
        {time[0]}
        {time[1] && <Time>{time[1]}</Time>}
      </Date>
      <Summary>
        <ColorBox color={event.backgroundColor} />
        <span>{event.summary}</span>
      </Summary>
      {!isMobile && (
        <Location>{event.location ? event.location : "-"}</Location>
      )}
    </Wrapper>
  );
};

export default SearchResult;

const Wrapper = styled.div<{ $ismobile: boolean }>`
  width: 100%;
  height: ${({ $ismobile }) => ($ismobile ? "5rem" : "3rem")};

  padding: 0 1rem;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  &:hover {
    background-color: #fccb1660;
  }
`;

const Date = styled.span<{ $ismobile: boolean }>`
  width: ${({ $ismobile }) => ($ismobile ? "auto" : "10rem")};
  font-size: 1rem;
  font-weight: 500;
`;
const Time = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  > span {
    width: 18rem;
  }
`;

const Location = styled.span`
  width: 15rem;
  font-size: 0.8rem;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
