import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface WithPasswordProtectionProps {
  // Additional props can be defined here if needed
}

function withPasswordProtection<P extends WithPasswordProtectionProps>(
  WrappedComponent: React.ComponentType<P>
) {
  return function WithPasswordProtection(props: P) {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const location = useLocation();

    useEffect(() => {
      const checkPassword = async () => {
        const storedAuth = sessionStorage.getItem("isAuthenticated");
        if (storedAuth === "true") {
          setIsAuthenticated(true);
          setIsLoading(false);
          return;
        }

        const password = prompt("Please enter the password:");
        if (password === "Douglas1@") {
          // Replace with your actual password
          setIsAuthenticated(true);
          sessionStorage.setItem("isAuthenticated", "true");
        } else {
          alert("Incorrect password. Access denied.");
        }
        setIsLoading(false);
      };

      checkPassword();
    }, []);

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <WrappedComponent {...props} />;
  };
}

export default withPasswordProtection;
