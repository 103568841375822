import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MENU, SUBMENU } from "@/constants/nav_bar";

const CurrentMenuBar = () => {
  const [menu, setMenu] = useState(null);
  const [subMenu, setSubMenu] = useState(null);
  const layoutRef = useRef();

  const textColor =
    menu === "Product" || menu === "Technology" ? "#ffffff" : "#000000";

  useEffect(() => {
    const getMenuName = event => {
      const target = event.detail.index - 1;
      if (target === -1) {
        setMenu(null);
        setSubMenu(null);
        return;
      }

      var index = 0;
      for (const key of MENU) {
        for (const subMenu of SUBMENU[key]) {
          if (index === target) {
            setMenu(key);
            setSubMenu(subMenu);
            return;
          } else index++;
        }
      }
    };

    document.addEventListener("onChangeMenu", getMenuName);

    return () => {
      document.removeEventListener("onChangeMenu", getMenuName);
    };
  }, []);

  useEffect(() => {
    const onChangeMenuColor = new CustomEvent("onChangeMenuColor", {
      bubbles: false,
      cancelable: false,
      detail: {
        menu: menu,
        subMenu: subMenu
      }
    });
    document.dispatchEvent(onChangeMenuColor);
  }, [menu, subMenu]);

  return (
    <Layout ref={layoutRef}>
      {menu && (
        <CurrentMenu $color={textColor}>
          {`SPACEMAP > ${menu} > `}
          <CurrentSubMenu>{`${subMenu}`}</CurrentSubMenu>
        </CurrentMenu>
      )}
    </Layout>
  );
};

export default CurrentMenuBar;

const Layout = styled.section`
  height: 2rem;

  position: fixed;
  top: 5rem;
  left: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const CurrentMenu = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${props => props.$color};
`;

const CurrentSubMenu = styled(CurrentMenu)`
  font-weight: 700;
  font-size: 1rem;
  color: #fccb16;
`;
