import styled from "styled-components";

const ColorBox = ({ color }) => {
  return <ColorSection $color={color} />;
};

export default ColorBox;

const ColorSection = styled.div<{ $color: string }>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;
