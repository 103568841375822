import { EVENT_SEARCH_URL } from "@/constants/calendar";

export const makeSearchUrl = (data: any) => {
  let url = EVENT_SEARCH_URL;
  const { search, start, end } = data;

  if (
    (!search || search === "") &&
    (!start || start === "") &&
    (!end || end === "")
  ) {
    return url;
  }
  let queries = [];
  if (search && search !== "") {
    queries.push(`q=${search}`);
  }
  if (start && start !== "") {
    queries.push(`start=${start}`);
  }
  if (end && end !== "") {
    queries.push(`end=${end}`);
  }

  const queryString = queries.join("&");

  url += `?${queryString}`;

  return url;
};
