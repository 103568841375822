import styled from "styled-components";
import EventCheckBox from "../EventCheckBox";
import { set, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useCalendarStore from "@/store/calendarStore";
import { EVENT_DETAIL_URL, EVENT_SEARCH_URL } from "@/constants/calendar";
import { makeSearchUrl } from "./SearchModule.utils";
import { Button } from "@/page/calendar/SpaceCalendarStyle";
import { SearchIcon } from "../EventCalendar";

const SearchModule = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const isDetailPage = window.location.pathname === EVENT_DETAIL_URL;

  const startDate = watch("start");

  const onSubmit = data => {
    const url = makeSearchUrl(data);
    navigate(url, { replace: true });
  };

  useEffect(() => {
    const q = new URLSearchParams(location.search);
    const value = q.get("q");
    if (value) {
      setValue("search", value);
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <SearchWrapper>
            <Input type="text" placeholder="Search" {...register("search")} />
            <Button onClick={handleSubmit(onSubmit)}>
              Search
              <SearchIcon src="/svg/search-yellow.svg" />
            </Button>
          </SearchWrapper>
          <HStack>
            <DateInput
              type={"date"}
              min={new Date().toISOString().split("T")[0]}
              {...register("start")}
            />
            <span>~</span>
            <DateInput type={"date"} {...register("end")} min={startDate} />
          </HStack>
        </Stack>
      </form>
      {!isDetailPage && <EventCheckBox />}
    </>
  );
};

export default SearchModule;

const SearchWrapper = styled.section`
  width: auto;
  height: 3rem;

  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Img = styled.img`
  width: 1.5rem;
  height: 1.5rem;

  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #001326;
  outline: none;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    background-clip: text;
  }
`;

const DateInput = styled(Input)`
  /* text-align: center; */
  /* padding-right: 1rem; */
  width: fit-content;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const HStack = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;
