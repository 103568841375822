import {
  ACCESS_TOKEN,
  EVENT_CALENDAR_URL,
  DESCRIPTION_FIELD
} from "@/constants/calendar";import { streamToJson } from "@/utils/common";
import axios from "axios";

export const fetchCalendarAccessToken = async () => {
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDAR_TOKEN_ENDPOINT,
    {
      redirect: "follow",
      method: "GET",
      headers: {
        "Content-Type": "text/plain"
      }
    }
  );

  const data = await streamToJson(response);
  const { token } = data.data;

  return token;
};

export const getPresignedUrl = async (filePath: string): string => {

  const response = await axios.post(
    `${process.env.REACT_APP_CALENDAR_API_URL}/presigned-urls`,
    {
      key: filePath
    }, {
      withCredentials: true,
    }
  );

  return response.data.preSignedUrl;
};

export const uploadFileToS3 = async (file: File, url: string) => {
  const response = await axios.put(url, file, {
    headers: {
      "Content-Type": file.type
    }
  });

  return response;
};

const makeDescriptionJSon = (data, speakers) => {
  const descriptionString = DESCRIPTION_FIELD.map(field => {
    if (field === DESCRIPTION_FIELD[6]) {
      return `${field}: ${speakers.map(speaker => `${speaker}`).join(", ")}`;
    }
    // else if(field === DESCRIPTION_FIELD[9]){

    //   const description = data[field];
    //   return `${field}: ${description}`;
    // }
    return `${field}: ${data[field]}`;
  }).join("\n");

  const jsonString = `${descriptionString}`;
  return jsonString;
}

export const makeEventForm = (data, timezone, speakers) => {
  const jsonString = makeDescriptionJSon(data, speakers);

  const endDate = new Date(data.end);
  endDate.setDate(endDate.getDate() + 1);
  
  const event = {
    summary: data.summary,
    location: data.location,
    description: jsonString,
    start:
      !data.duration
        ? {
            dateTime: `${data.start}:00`,
            timeZone: timezone
          }
        : {
            date: data.start
          },
    end:
    !data.duration
        ? {
            dateTime: `${data.end}:00`,
            timeZone: timezone
          }
        : {
            date: endDate.toISOString().split("T")[0]
          }
  };
  return event;
};


export const makeFileName = (key: string, origin: string) => {
  const extension = origin.split(".").pop();

  return `${key}.${extension}`;
};

export const uploadFile = async (key: string, file: File) => {
  const uploadUrl = await getPresignedUrl(key);
  const response = await uploadFileToS3(file, uploadUrl);
  return response;
};

