import React from "react";
import styled from "styled-components";

const TableLoadingSpinner = ({ width, height }) => (
  <SpinnerWrapper>
    <StyledWrapper width={width} height={height} />
  </SpinnerWrapper>
);

export default TableLoadingSpinner;

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.div`
  background-color: transparent;
  width: ${props => props.width};
  height: ${props => props.height};
  border: 5px solid white;
  border-radius: 50%;
  border-top-color: royalblue;
  animation: spin 1s infinite ease-in-out;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
