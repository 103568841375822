import styled from "styled-components";

export const Layout = styled.section`
  display: grid;

  padding: auto;

  grid-template-columns: [column1] 1fr [column2] 1fr [column3];
  grid-template-rows: [row1] 1fr [row2] 2fr [row3];
  row-gap: 3rem;
  > :nth-child(1) {
    grid-area: row1/column1/row3/column2;
    align-self: flex-start;
    justify-self: flex-end;
  }
  > :nth-child(2) {
    grid-area: row1/column2/row2/column3;
    align-self: flex-start;
    justify-self: flex-start;
  }
  > :nth-child(3) {
    grid-area: row2/column2/row3/column3;
    align-self: flex-start;
    justify-self: flex-start;
  }
`;

export const Content = styled.section`
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  /* align-items: center; */
  gap: 1rem;
`;

export const ContactContent = styled(Content)`
  gap: 0rem;
`;

export const GoogleMapWrapper = styled.section`
  place-self: end;
`;

export const ContactWrapper = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: 800;
  color: #000000;
  margin: 0;
`;

export const Address = styled.span`
  font-weight: 700;
  font-size: 1rem;
  color: #000000;
`;

export const Email = styled.span`
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  color: #00b0f0;
`;

export const EmailWrapper = styled.section`
  display: flex;
  align-items: flex-end;
  gap: 0.3rem;
  > a {
    text-decoration: none;
  }
`;
