import { FAQ } from "@/constants/faq";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import styled, { css } from "styled-components";

const Faq = () => {
  return (
    <Layout $ismobile={isMobile}>
      <Title $ismobile={isMobile}>Frequently Asked Questions</Title>
      <Accordion
        allowToggle
        defaultIndex={[0]}
        border={"1px solid lightgray"}
        borderRadius={"1rem"}
        padding={"1rem"}
      >
        {FAQ.map((faq, index) => {
          return (
            <AccordionItem key={index} color={"#001326"}>
              <h2 style={{ margin: "0", padding: "0" }}>
                <AccordionButton
                  bg={"transparent"}
                  padding={"1rem 2rem"}
                  border={"none"}
                  cursor={"pointer"}
                  _hover={{ bg: "#E9E9E9", borderRadius: "1rem" }}
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize={"1.5rem"}
                    fontWeight={"900"}
                    color={"#001326"}
                  >
                    {faq.question}
                  </Box>
                  <AccordionIcon w={"1.5rem"} height={"1.5rem"} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                margin={"0.5rem 2rem"}
                padding={"0.5rem 1rem"}
                borderRadius={"1rem"}
                bg={"#fccb16a0"}
                whiteSpace="pre-line"
                fontSize={"1.2rem"}
              >
                {faq.answer}
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Layout>
  );
};

export default Faq;

const Layout = styled.section<{ $ismobile: boolean }>`
  position: relative;
  width: ${({ $ismobile }) => ($ismobile ? "90%" : "80%")};
  height: 100%;
  margin: 0 auto;
  padding: 5rem 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    gap: 2rem;
    :nth-child(n) {
      font-size: 1.5rem;
    }
  }
`;

const Title = styled.h2<{ $ismobile: boolean }>`
  margin: 3rem 0 5rem 0;
  padding: 0;
  font-size: 2.5rem !important;
  font-weight: 700;

  text-align: center;
  color: #001326;
  ${({ $ismobile }) =>
    $ismobile &&
    css`
      margin: 0;
      margin-bottom: 2rem;
    `}
`;
