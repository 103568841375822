import styled from "styled-components";
import useCalendarStore from "@/store/calendarStore";
import { SELECT_TYPE, CALENDAR_ITEM_COLOR } from "@/constants/calendar";
import ColorBox from "../ui/ColorBox";

const EventCheckBox = () => {
  const { checkedEvent, setCheckedEvent } = useCalendarStore();
  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    setCheckedEvent({ ...checkedEvent, [name]: checked });
  };

  return (
    <CheckBoxSection>
      {SELECT_TYPE.map((type, index) => {
        return (
          <Label key={index}>
            <input
              type="checkbox"
              name={type}
              checked={checkedEvent[type]}
              onChange={handleCheckboxChange}
            />
            <ColorBox color={CALENDAR_ITEM_COLOR[type]} />
            {type}
          </Label>
        );
      })}
    </CheckBoxSection>
  );
};

export default EventCheckBox;

const CheckBoxSection = styled.section`
  display: flex;

  flex-wrap: wrap;
  max-width: 50%;
  flex: 0.8;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 1rem;
  margin-right: 0.6rem;

  font-weight: 500;
  white-space: nowrap;
  input {
    font-size: 1rem;
    margin-left: 0;
  }
`;
