import styled from "styled-components";

const PlatformLink = () => {
  const PLATFORM_URL = "https://platform.spacemap42.com/";
  const onClick = () => {
    window.open(PLATFORM_URL, "_blank");
  };

  return <Platformlink onClick={onClick}>Go to Platform</Platformlink>;
};

export default PlatformLink;

const Platformlink = styled.section`
  width: 10rem;
  padding: 0.3rem 0.5rem;

  background-color: #fccb16;
  border-radius: 0.625rem;

  color: #000000;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;

  cursor: pointer;
`;
